import React, { useState, useEffect } from "react";

import { Image } from "../../atoms/Image";
import { Overlay, Controls, Capsule, Cursor } from "./styles";
import img3 from "../../../images/office-2.webP";
import { motion } from "framer-motion";
import { CarouselData } from "../../../services/CarouselData";
import { HomeText } from "../HomeText";
import { HolderContent } from "../../atoms/HolderContent";
import { Text } from "../../atoms/Text";

export function Carrousel({ children }) {
  const images = [img3];
  const textData = CarouselData();
  const [index, setIndex] = useState(0);

  const variants = {
    prev: { x: "-" + index * 100 + "vw" },
  };

  function scrollDown() {
    window.scroll({
      top: 750,
      left: 0,
      behavior: "smooth",
    });
  }

  function moveNext() {
    if (index === images.length - 1) setIndex(0);
    else setIndex(index + 1);
  }
  useEffect(() => {
    const interval = setInterval(() => {
      moveNext();
    }, 8000);
    return () => clearInterval(interval);
  }, [index]);

  function movePre() {
    if (index === 0) setIndex(images.length - 1);
    else setIndex(index - 1);
  }

  return (
    <div className="hide">
      <Controls className="position-absolute d-flex flex-column justify-content-between pt-8">
        <HolderContent className="h-100">
          <HomeText
            title={textData[index].title}
            link={textData[index].link}
            description={textData[index].description}
            linkDesc={textData[index].linkDesc}
            color={textData[index].color}
          ></HomeText>
        </HolderContent>
        <HolderContent>
          <div className="d-flex justify-content-between alifn-self-end">
            <Cursor onClick={() => scrollDown()}>
              <svg
                width="59"
                height="59"
                viewBox="0 0 59 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="29.5"
                  cy="29.5"
                  r="29.5"
                  transform="rotate(-90 29.5 29.5)"
                  fill="white"
                  fill-opacity="0.1"
                />
                <path
                  d="M34 32L30.1768 35.8232C30.0791 35.9209 29.9209 35.9209 29.8232 35.8232L26 32"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path d="M30 35.5V23" stroke="white" stroke-linecap="round" />
              </svg>
            </Cursor>
            <div className="d-flex align-items-center mb-6">
              <Capsule className="align-items-center justify-content-evenly ">
                <div
                  className="d-flex align-items-center p-2"
                  onClick={() => {
                    movePre();
                  }}
                >
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 1L1.17678 4.82322C1.07914 4.92085 1.07915 5.07915 1.17678 5.17678L5 9"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path d="M1.5 5L14 5" stroke="white" stroke-linecap="round" />
                  </svg>
                </div>
                <div
                  className="d-flex align-items-center p-2"
                  onClick={() => {
                    moveNext();
                  }}
                >
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1L13.8232 4.82322C13.9209 4.92085 13.9209 5.07915 13.8232 5.17678L10 9"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path d="M13.5 5L1 5" stroke="white" stroke-linecap="round" />
                  </svg>
                </div>
              </Capsule>
              <Text className="m-0 ps-4 text-white">
                {index + 1} / {images.length}
              </Text>
            </div>
          </div>
        </HolderContent>
      </Controls>
      <Overlay className=""> </Overlay>
      <motion.div
        variants={variants}
        initial={{ x: "-" + index * 100 + "vw" }}
        animate={"prev"}
        transition={{ duration: 1, type: "smooth" }}
        className="d-flex flex-row "
      >
        <Image minWidth={"100vw"} image={images[0]} mobileHeigth="750px" deskHeigth="750px"></Image>
        <Image minWidth={"100vw"} image={images[1]} mobileHeigth="750px" deskHeigth="750px"></Image>
        <Image minWidth={"100vw"} image={images[2]} mobileHeigth="750px" deskHeigth="750px"></Image>
        <Image
          minWidth={"100vw"}
          image={images[3]}
          mobileHeigth="750px"
          deskHeigth="750px"
        ></Image>{" "}
      </motion.div>
    </div>
  );
}
