import styled from "styled-components";

export const Overlay = styled.div`
    background-color: rgba(7,9,40,0.2);
    z-index: 5;
    width: 100%;
    height: 750px;
    position: absolute;
     
`;

export const Controls = styled.div`
    z-index: 50;
    width: 100%;
    height: 750px;
    position: absolute;
     
`;
export const Capsule = styled.div`
cursor:pointer;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display:flex;
    height: 50px;
    width:100px;
`;
export const Cursor = styled.div`
cursor:pointer;
&:hover svg circle{
    fill-opacity:0.4;

}
`
