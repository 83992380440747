import I18n from '@s-ui/i18n'
import Polyglot from '@s-ui/i18n/lib/adapters/polyglot'

import {esES} from './es-ES'
import {enUS} from './en-US'

const i18n = new I18n({adapter: new Polyglot()})

i18n.languages = {
  'es-ES': esES,
  'en-US': enUS
}

export const i18nFactory = ({lang}) => {
  i18n.culture = lang
  return i18n
}