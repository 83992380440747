import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
export const Parraf = styled.p`
    font-size: ${({fsMobile, fs})=>fsMobile? fsMobile:fs};
    font-weight: ${({fweight='400'})=>fweight};
    white-space: pre-line;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: ${({fs})=>fs};

    }

`;