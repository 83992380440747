import styled from "styled-components";
import {Link } from "react-router-dom";
import  Breakpoints  from "../../../styles/breakpoints";


export const StyledLink = styled(Link)`
    text-decoration:none;
    color: ${({color }) => `${color} `? color: '' } ;
    font-size: ${({fs = '14px'}) => fs};
    font-style: normal;
    font-weight: ${({fw = '400'}) => fw};
    text-align: ${({ta = 'left'}) => ta};
    letter-spacing: 0em;
    margin: ${({m = '0px'}) => m};
    width:  ${({ w }) => w};
    height:  ${({ h  }) => h};
    border-radius:${({ br}) => br};
    padding: ${({ p  }) => p}; 

    &:hover{
        text-decoration: ${({underline=true})=>underline?'underline': ''};
        color: ${({changeColor }) => `${changeColor} !important`? changeColor: '' } ;
    }
    
    }
    @media (min-width: ${Breakpoints.tablet}) {
        font-size: ${({fzTablet }) => fzTablet};
        height:  ${({ hTablet  }) => hTablet};
        width:  ${({ wTablet  }) => wTablet};
    };
    @media (min-width: ${Breakpoints.mobile}) {
        font-size: ${({fzMobile}) => fzMobile};
        height:  ${({ hMobile  }) => hMobile};
        width:  ${({ wMobile  }) => wMobile};
    };


    
    `;