import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const Styledh1 = styled.h1`
    font-weight: ${({ weight = "700" }) => weight};
    font-size: 38px;
    

    @media (min-width: ${Breakpoints.tablet}) {
        font-size: 64px;
       
    }


`;