import React, { useState } from "react";
import "./App.css";
import { Home } from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "./components/molecules/Header";
import ScrollToTop from "./components/atoms/ScrollToTop";
import { DigitalBusiness } from "./components/pages/DigitalBusiness";
import { Technology } from "./components/pages/Technology";
import { AboutUs } from "./components/pages/AboutUs";
import { DigitalWorkshop } from "./components/pages/DigitalWorkshop";
import { Contact } from "./components/pages/Contact";
import { CookiesPolicy } from "./components/pages/CookiesPolicy";
import { PrivacyPolicy } from "./components/pages/PrivacyPolicy";
import { TermsAndConditions } from "./components/pages/TermsAndConditions";
import { Navigator } from "./components/molecules/Navigator";

function App() {
  var cpm = {};
  (function (h, u, b) {
    var d = h.getElementsByTagName("script")[0],
      e = h.createElement("script");
    e.async = true;
    e.src = "https://cookiehub.net/c2/e3fe1c35.js";
    e.onload = function () {
      u.cookiehub.load(b);
    };
    d.parentNode.insertBefore(e, d);
  })(document, window, cpm);

  return (
    <Router>
      <ScrollToTop />
      <Route path="/negociodigital" exact>
        <Header fix={true}></Header>
        <Navigator></Navigator>
        <DigitalBusiness></DigitalBusiness>
      </Route>
      <Route path="/tecnologia" exact>
        <Header fix={true}></Header>
        <Navigator></Navigator>
        <Technology></Technology>
      </Route>
      <Route path="/sobrenosotros" exact>
        <Header fix={true}></Header>
        <Navigator></Navigator>
        <AboutUs></AboutUs>
      </Route>
      <Route path="/digitalworkshop" exact>
        <Header fix={true}></Header>
        <Navigator></Navigator>
        <DigitalWorkshop></DigitalWorkshop>
      </Route>
      <Route path="/politicacookies" exact>
        <Header fix={true}></Header>
      </Route>
      <Route path="/terminosycondiciones" exact>
        <Header fix={true}></Header>
        <TermsAndConditions></TermsAndConditions>
      </Route>
      <Route path="/politicaprivacidad" exact>
        <Header fix={true}></Header>
        <PrivacyPolicy></PrivacyPolicy>
      </Route>
      <Route path="/contacto" exact>
        <Header fix={true}></Header>
        <Contact></Contact>
      </Route>
      <Route path="/" exact>
        <Header fix={false}></Header>
        <Home></Home>
      </Route>
    </Router>
  );
}

export default App;
