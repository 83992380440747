import React, {useContext} from "react";
import { Heading } from "../../atoms/Heading";
import { Text } from "../../atoms/Text";
import { Anchor } from "../../atoms/Anchor";
import { Global}  from "../../../contexts/Global"
export function TextContact(props) {
    const {i18n} = useContext(Global);
  return (
    <div>
      <Heading   className=' ' color={"blacktext"}>{i18n.t("CONTACT_TITLE")}</Heading>

        <div className='border-top border-blacktext border-2 mt-3 mb-5'></div>
      
        <Text fs='18px' fweight='500' >
        {i18n.t("CONTACT_OFICINE")}
        </Text>
     
        <Text fs='18px'  >
        {i18n.t("CONTACT_MAIL")}
        </Text>
     
        <Text fs='18px' >
        {i18n.t("CONTACT_NUM")}
        </Text>
    </div>
  );
}
