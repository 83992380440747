import React  from "react";
import { Square } from "../../atoms/Square";
import { Triangle } from "../../atoms/Triangle";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export function FiguresTriangle({ colordetail , reversed}) {

  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
  });
  return (
    <div className={`d-flex col  ${reversed && 'justify-content-end'}`}>
    <div>
      <motion.div 
      ref={titleRef}
        initial={{ scale:0}}
        animate={{ scale:titleInView ?1:0}}
        transition={{ duration: 0.75}}
      ><Square color={colordetail}></Square></motion.div>
      <motion.div
      ref={titleRef}
        initial={{y: '-100%' , opacity:0}}
        animate={{ y:titleInView ?["-100%", "0%"]:'-100%', opacity:titleInView ?0.6:0 }}
        transition={{ duration: 0.75, delay:0.75 }}
      ><Triangle color={colordetail}  ></Triangle></motion.div>
    </div>
    <div>
    <motion.div
    ref={titleRef}
        initial={{y: '100%' , opacity:0}}
        animate={{ y:titleInView ?["100%", "0%"]:'100%', opacity:titleInView ?0.3:0 }}
        transition={{ duration: 0.75, delay:0.5 }}
      ><Triangle color={colordetail}  ></Triangle></motion.div>
      <motion.div 
      ref={titleRef}
        initial={{ scale:0}}
        animate={{ scale:titleInView ?1:0}}
        transition={{ duration: 0.75, delay:0.25}}
      ><Square color={colordetail}></Square></motion.div>
  </div>
  </div>
);
}
