import React, {useContext} from "react";
import { Global } from "../../contexts/Global";
import { Footer } from "../molecules/Footer";
import { PrivacyPolicyTemplate } from "../templates/PrivacyPolicyTemplate";
import { OtherLinks } from "../molecules/OtherLinks";
import { PrivacyPolicyData } from "../../services/PrivacyPolicyData";

export function PrivacyPolicy(props) {
  const {i18n} = useContext(Global);
  const data = PrivacyPolicyData();
  return (
      <>
    <PrivacyPolicyTemplate PrivacyPolicyData={data} otherLinks={<OtherLinks/>} tit={i18n.t("PRIVACY_POLICY_TITLE")}>
    </PrivacyPolicyTemplate>
    <Footer color='primary'></Footer>
    </>
  );
}
