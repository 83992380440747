import React, { useState, useEffect } from "react";
import { HolderContent } from "../../atoms/HolderContent";
import { Text } from "../../atoms/Text";
import { Opacity,  Rect , MenuButton,SideText} from "./styles";
import { HeaderHolder } from "../../atoms/HeaderHolder";
import img3 from "../../../images/office-2.webP";
import { HeaderLinks } from "../HeaderLinks";
import {  AnimatePresence } from "framer-motion";
import { Logo } from "../../atoms/Logo";

export function Header({ fix }) {
  let white = fix ? "black" : "white";
  const [positionScroll, setPositionScroll] = useState(0);
  const [color, setColor] = useState(white);
  const [collapse, setCollapse] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setPositionScroll(position / 360);
    if (position < 150) setColor(white);
    else setColor("black");
  };
  function handleCollapse(ser) {
    setCollapse(ser);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <>
    {/*<SideText><Text className={'text-white'}>Descubre más</Text></SideText>*/}
      {collapse && (
        <Opacity
          opacity={positionScroll}
          fix={fix}
          className={`fixed-top py-3 `}
        >
          <HolderContent>
            <div className="d-flex justify-content-between align-items-center">
              <Text className=" mb-0" fs="15px">
                <Logo color={color} />
              </Text>
              <MenuButton onClick={() => handleCollapse(false)} >
                <Text className="mb-0 h-100 py-3 ps-3 " fs="15px"  >
                  <svg
                    color={color}
                    width="24"
                    height="11"
                    viewBox="0 0 24 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Rect color={color} y="10" width="24" height="1" fill="white" />
                    <Rect color={color} x="12" width="12" height="1" fill="white" />
                  </svg>
                </Text>
              </MenuButton>
            </div>
          </HolderContent>
        </Opacity>
      )}
      <AnimatePresence>
        {!collapse && (
          <HeaderHolder
            onClick={() => handleCollapse(true)}
            image={img3}
            text={
              <HeaderLinks ></HeaderLinks>
            }
          >
            {" "}
          </HeaderHolder>
        )}
      </AnimatePresence>
    </>
  );
}
