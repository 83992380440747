import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import { Anchor } from "../../atoms/Anchor";
import { Global } from "../../../contexts/Global";
import { HolderContent } from "../../atoms/HolderContent";
import { Div } from "./styles";
export function Navigator(props) {
  const { i18n } = useContext(Global);
  const location = useLocation();
  return (
    <Div className="bg-graynav position-fixed w-100 py-2 d-none d-lg-block">
      <HolderContent>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Anchor
            to='/sobrenosotros'
              underline={false}
              color={`${location.pathname === '/sobrenosotros' ? '#3452FF': '#AAABB4'}`}
              fw={`${location.pathname === '/sobrenosotros' ? '500': ''}`}
              changeColor="#3452FF"
              
            >
              {i18n.t("ABOUT_US")}
            </Anchor>
            <Anchor
            to='/tecnologia'
              underline={false}
              className=" ms-5"
              color={`${location.pathname === '/tecnologia' ? '#3452FF': '#AAABB4'}`}
              fw={`${location.pathname === '/tecnologia' ? '500': ''}`}
              changeColor="#3452FF"
            >
              {i18n.t("TECH")}
            </Anchor>
            <Anchor
            to='/negociodigital'
              underline={false}
              color={`${location.pathname === '/negociodigital' ? '#3452FF': '#AAABB4'}`}
              fw={`${location.pathname === '/negociodigital' ? '500': ''}`}
              className=" ms-5"
              changeColor="#3452FF"
            >
              {i18n.t("DIGITAL_BUSINESS")}
            </Anchor>
            <Anchor
            to='/digitalworkshop'
              underline={false}
              color={`${location.pathname === '/digitalworkshop' ? '#3452FF': '#AAABB4'}`}
              fw={`${location.pathname === '/digitalworkshop' ? '500': ''}`}
              className=" ms-5"
              changeColor="#3452FF"
            >
              {i18n.t("DIGITAL_WORKSHOP")}
            </Anchor>
          </div>
          <div>
            <Anchor
              to={"/contacto"}
              underline={false}
              fw='500'
              className={`bg-primary d-inline-flex align-items-center text-white btn px-4`}
              h="48px"
              fs="16px"
            >
              {i18n.t("CONTACTING")}
            </Anchor>
          </div>
        </div>
      </HolderContent>
    </Div>
  );
}
