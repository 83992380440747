import React, {useContext} from "react";
import { Global } from "../contexts/Global";
import { FiguresDetail } from "../components/molecules/FiguresDetail";
import { FiguresDetailThree } from "../components/molecules/FiguresDetailThree";
import { FiguresDetailTwo } from "../components/molecules/FiguresDetailTwo";
import { FiguresTriangle } from "../components/molecules/FiguresTriangle"; 

export function HomeData (){
    const { i18n } = useContext(Global);
    const title = {
      title: i18n.t("HOME_TITLE"),
      description: i18n.t("HOME_DESC")
    }
    const data = [
        {
          to:'/sobrenosotros',
          title:i18n.t("ABOUT_US_TITLE"),
          description:
          i18n.t("ABOUT_US_DESC"),
          link: i18n.t("KNOW_US"),
          word: i18n.t("US"),
          reversed: false,
          color: "secondary",
          figuresDetail: FiguresDetail,
          triangle: false,
        },
        {
          title: i18n.t("TECH_FIRST_TITLE"),
          description:
          i18n.t("TECH_FIRST_DESCRIPTION"),
          link: i18n.t("DISCOVER_MORE"),
          word: i18n.t("TECH"),
          reversed: true,
          color: "primary",
          figuresDetail: FiguresDetailTwo,
          triangle: false,
          to:'/tecnologia'
        },
        {
          title:
          i18n.t("WE_BUILD_STARTUPS_TITLE"),
          description:
          i18n.t("WE_BUILD_STARTUPS_DESC"),
          link: i18n.t("DISCOVER_MORE"),
          word: i18n.t("DIGITAL_BUSINESS"),
          reversed: false,
          color: "tertiary",
          figuresDetail: FiguresDetailThree,
          triangle: false,
          to:'/negociodigital'
        },
        {
          title:
          i18n.t("DIGITAL_WORKSHOP_TITLE"),
          description:
          i18n.t("DIGITAL_WORKSHOP_DESC"),
          link: i18n.t("INSPIRE_WITH"),
          word: i18n.t("DIGITAL_WORKSHOP"),
          reversed: true,
          color: "quaternary",
          figuresDetail: FiguresTriangle,
          triangle: true,
          to:'/digitalworkshop'
        },
      ];
      return {data, title};
}