const breakpoints = {
    mobile: "767px",
    msSmall: "576px",
    mobileWidth: "90%",
    mobilePlus: "768px",
    tabletWidth: "90%",
    tablet: "992px",
    desktop: "1400px",
    desktopWidth: "85%",
    width:"80%",
    widthContent: '75%',
    desktopWidthContent: "80%",
    tabletWidthContent: "85%",
    mobileWidthContent: "90%",
};

export default breakpoints;
