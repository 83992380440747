import React from 'react'
import { Div } from './styles'


export function Circle ({color = 'primary', opacity,  className, trans}) {
  return (
    <Div opacity={opacity} className={`bg-${color} ${className}`} trans={trans} >
     
    </Div>
  )
}
