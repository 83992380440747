import styled from "styled-components";
import { motion } from "framer-motion";
import  Breakpoints  from "../../../styles/breakpoints";
export const Div = styled(motion.div)`
    position: fixed;
    top:90px;
    z-index:60;
    border-radius:7px;
    width: 90%;
    cursor:pointer;
    -webkit-transform: translateZ(60);

    
    @media (min-width: ${Breakpoints.tablet}) {
        width: 500px;
       
    }



`;