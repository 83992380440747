import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const Div = styled.div`
    --size-sm: 90px;
    --size-sm-half: 45px;
    --trans-sm: -45px;
    --size-md: 150px;
    --size-md-half: 75px;
    --trans-md: -75px;
    --size-xl: 180px;
    --size-xl-half: 90px;
    --trans-xl: -90px;


    width: 0;
    height: 0;
    border-left: var(--size-sm-half) solid transparent;
    border-right: var(--size-sm-half) solid transparent;
    border-bottom: var(--size-sm) solid var(--bs-${({color})=>color});
    ${({trans})=> trans};

    opacity: ${({opacity})=> opacity};

   @media (min-width: ${Breakpoints.tablet}) {
       
    border-left: var(--size-md-half) solid transparent;
    border-right: var(--size-md-half) solid transparent;
    border-bottom: var(--size-md) solid var(--bs-${({color})=>color});
    ${({transMd})=> transMd}
    }

    @media (min-width: ${Breakpoints.desktop}) {
        border-left: var(--size-xl-half) solid transparent;
        border-right: var(--size-xl-half) solid transparent;
        border-bottom: var(--size-xl) solid var(--bs-${({color})=>color});
        ${({transXl})=> transXl}
    }

   
   
    
`;