import React  from "react";
import { useInView } from "react-intersection-observer";
import { Square } from "../../atoms/Square";
import { Circle } from "../../atoms/Circle";
import { motion } from "framer-motion"

export function FiguresDetail({ colordetail , circle, triangle, reversed}) {
 
 
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });


  return (
    <div className={`d-flex col  ${reversed && 'justify-content-end'}`}>
      <div>
        <motion.div 
        ref={titleRef}
          initial={{ scale:0}}
          animate={{ scale:titleInView ?1:0}}
          transition={{ duration: 0.75}}
        ><Square color={colordetail}></Square></motion.div>
        <motion.div
        ref={titleRef}
          initial={{y: '-100%' , opacity:0}}
          animate={{ y:titleInView?["-100%", "0%"]:'-100%', opacity:titleInView?0.3:0 }}
          transition={{ duration: 0.75, delay:0.5 }}
        ><Circle color={colordetail}  ></Circle></motion.div>
      </div>
      <div>
      <motion.div
      ref={titleRef}
          initial={{x: '-100%' , opacity:0}}
          animate={{ x:titleInView?["-100%", "-50%"]:"-100%", opacity:titleInView?0.6:0}}
          transition={{ duration: 0.75, delay:0.75 }}
        ><Circle color={colordetail}  ></Circle></motion.div>
        <motion.div 
        ref={titleRef}
          initial={{ scale:0}}
          animate={{ scale:titleInView?1:0}}
          transition={{ duration: 0.75, delay:0.25}}
        ><Square color={colordetail}></Square></motion.div>
      </div>
    </div>
  );
}
