import React from "react";
import { HolderContent } from "../../atoms/HolderContent";
import { LegalText } from "../../molecules/LegalText";
import { OtherLinksHolder } from "../../atoms/OtherLinksHolder";
import { HeadingM } from "../../atoms/HeadingM";

export function TermsAndConditionsTemplate({TermsAndConditionsData, otherLinks, tit}) {
  return (
    <div className='mt-8'>
      <HolderContent>
        <div className='mt-9 mb-9 mt-lg-10 mb-lg-10 d-flex justify-content-between flex-column-reverse flex-lg-row'>
          <div className='col-12 col-lg-8'>
          <HeadingM color='blacktext' > {tit}</HeadingM>
            {TermsAndConditionsData.map((item,index)=>{
              return (<div key={index+'PrivacyPolicy'}>
                <LegalText title={item.title} description={item.description}></LegalText>
              </div>)
            })}
          </div>
          <OtherLinksHolder className='col-12 col-lg-3 flex-lg-column' >
              {otherLinks}
          </OtherLinksHolder>

        </div>
      </HolderContent>
    </div>
  );
}
