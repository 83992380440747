import React, { useContext } from "react";
import { Global } from "../contexts/Global";

export function CarouselData() {
  const { i18n } = useContext(Global);

  const data = [
    {
      title: i18n.t('CARROUSEL_TITLE_ONE'),
      description: i18n.t('CARROUSEL_DESC_ONE'),
      linkDesc: i18n.t('CARROUSEL_LINK_ONE'),
      link: '/sobrenosotros',
      color:'primary'
    },
    {
      title: i18n.t('CARROUSEL_TITLE_ONE'),
      description: i18n.t('CARROUSEL_DESC_ONE'),
      linkDesc: i18n.t('CARROUSEL_LINK_ONE'),
      link: '/sobrenosotros',
      color:'primary'
    },
    {
      title: i18n.t('CARROUSEL_TITLE_ONE'),
      description: i18n.t('CARROUSEL_DESC_ONE'),
      linkDesc: i18n.t('CARROUSEL_LINK_ONE'),
      link: '/sobrenosotros',
      color:'primary'
    },
    {
      title: i18n.t('CARROUSEL_TITLE_ONE'),
      description: i18n.t('CARROUSEL_DESC_ONE'),
      linkDesc: i18n.t('CARROUSEL_LINK_ONE'),
      link: '/sobrenosotros',
      color:'primary'
    },
 
  ];
  return data;
}
