import React from "react";
import { HeadingM } from "../../atoms/HeadingM";
import { TextDropDown } from "../../molecules/TextDropDown";
import { LineColor } from "../../atoms/LineColor";
/**
 * 
 * @param {info} array [{title,descripton}] 
 *  
 */
export function DropwDownInfo({ titleColor, title, color, info }) {
  return (
    <div className=''>
      <HeadingM color='blacktext'>
        <span className={`text-${color}`}>{titleColor}</span> {title}
      </HeadingM>
      <div className='mt-6'>
      <LineColor color='blacktext'></LineColor>
      {info.map((item, index) => {
        return (
          <div key={index+'dropdown'}>
            <TextDropDown
              title={item.title}
              description={item.description}
            ></TextDropDown>
            <LineColor color='blacktext'></LineColor>
          </div>
        );
      })}</div>
    </div>
  );
}
