import React from "react";
import { Div } from "./styles";
import { Text } from "../../atoms/Text";

export function Modal({ tilte, description, color, close }) {
  return (
    <Div
      className={`bg-${color} p-3 ps-3`}
      onClick={() => close("")}
      initial={{ opacity: 0, x: 400 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 400 }}
      transition={{ duration: 0.5, type: "smooth" }}
    >
      <div className="d-flex justify-content-end  w-100">
        <svg
          className="position-fixed"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.737985 1.3086C0.449784 1.00924 0.449785 0.523879 0.737986 0.22452C1.02619 -0.0748396 1.49345 -0.0748399 1.78165 0.22452L13.7838 12.6914C14.0721 12.9908 14.0721 13.4761 13.7838 13.7755C13.4956 14.0748 13.0284 14.0748 12.7402 13.7755L0.737985 1.3086Z"
            fill={color === "tertiary" ? "#070928" : "#FFFFFF"}
          />
          <path
            d="M1.25982 13.7755C0.971618 14.0748 0.504352 14.0748 0.216151 13.7755C-0.0720502 13.4761 -0.0720504 12.9908 0.216151 12.6914L12.2183 0.224519C12.5065 -0.0748401 12.9738 -0.0748396 13.262 0.22452C13.5502 0.523879 13.5502 1.00924 13.262 1.3086L1.25982 13.7755Z"
            fill={color === "tertiary" ? "#070928" : "#FFFFFF"}
          />
        </svg>
      </div>

      <Text
        fweight="500"
        fs="14px"
        className={`m-0  text-${color === "tertiary" ? "textblack" : "white"}`}
        color={color === "tertiary" ? "textblack" : "white"}
      >
        {tilte}
      </Text>
      <Text
        fs="14px"
        className={`mt-2 mb-0 text-${
          color === "tertiary" ? "textblack" : "white"
        }`}
      >
        {description}
      </Text>
    </Div>
  );
}
