import React from "react";
import { TextDescription } from "../../molecules/TextDescription";

export function Information({
  reversed,
  word,
  description,
  title,
  link,
  color,
  FiguresDetail,
  triangle,
  to,
  detail}) {
  return (
    <div
      className={`d-flex flex-column  ${!reversed && "flex-lg-row"}  ${
        reversed && `flex-lg-row-reverse`
      } justify-content-between`}
    >
      {FiguresDetail && <FiguresDetail colordetail={color} reversed={reversed}></FiguresDetail>}
    
    <div className='col-12 col-lg-7'>
      <TextDescription
      to={to}
        figure={FiguresDetail}
        detail={detail}
        word={word}
        description={description}
        title={title}
        link={link}
        color={color}
        triangle={triangle}
      ></TextDescription></div>
    </div>
  );
}
