import styled from "styled-components";
import Breakpoints from "../../../styles/breakpoints";
import { motion } from "framer-motion";

export const Div = styled(motion.div)`
  height: ${({mobileHeigth = '750px'})=> mobileHeigth};
  background-size: cover;
  background-position: center;
  background-image: url(${({ imageBg }) => imageBg});
  min-width:${({ minWidth }) => minWidth};

  @media (min-width: ${Breakpoints.tablet}) {
    height: ${({deskHeigth = '750px'})=> deskHeigth};
   

}

`;
