import React from "react";
import { Path } from "./styles";
import { motion } from "framer-motion";
import { Anchor } from "../Anchor";
export function Logo({ color }) {
  

  return (
    <Anchor to='/'>
                <svg
                  width="106"
                  height="19"
                  viewBox="0 0 106 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.path
                    initial={{ y: 0 }}
                    animate={{ y: [0, -4, 0, -4,  0]}}
                    transition={{ type: "smooth", duration: 3, delay: 0.5 }}
                    d="M9.49379 12.7576C10.2717 12.7576 10.9024 12.1263 10.9024 11.3475C10.9024 10.5688 10.2717 9.9375 9.49379 9.9375C8.71585 9.9375 8.08521 10.5688 8.08521 11.3475C8.08521 12.1263 8.71585 12.7576 9.49379 12.7576Z"
                    fill="#3456FF"
                  />
                  <motion.path
                    initial={{ y: 0 }}
                    animate={{ y: [0, -5, 0, -5,  0] }}
                    transition={{ type: "smooth", duration: 3, delay: 0.25 }}
                    d="M5.45375 12.7576C6.23169 12.7576 6.86234 12.1263 6.86234 11.3475C6.86234 10.5688 6.23169 9.9375 5.45375 9.9375C4.67581 9.9375 4.04517 10.5688 4.04517 11.3475C4.04517 12.1263 4.67581 12.7576 5.45375 12.7576Z"
                    fill="#3456FF"
                  />
                  <motion.path
                    initial={{ y: 0 }}
                    animate={{ y: [0, -3, 0, -3,  0] }}
                    transition={{ type: "smooth", duration: 3 }}
                    d="M1.40859 12.7576C2.18653 12.7576 2.81717 12.1263 2.81717 11.3475C2.81717 10.5688 2.18653 9.9375 1.40859 9.9375C0.630646 9.9375 0 10.5688 0 11.3475C0 12.1263 0.630646 12.7576 1.40859 12.7576Z"
                    fill="#3456FF"
                  />
                  <Path
                    initial={{opacity:0,  y:-20}}
                    animate={{ opacity: 1, y :0}}
                    transition={{ type: "spring", duration: 0.5 ,delay:0.2}}
                    color={color}
                    d="M29.2861 0.0206604V17.6488H26.7063V16.1768H26.6547C26.2017 16.7612 25.6177 17.2307 24.95 17.5474C24.2822 17.8641 23.5494 18.0192 22.8107 18C19.5859 18 17.197 15.4846 17.197 11.3423C17.197 7.2 19.5859 4.68465 22.8107 4.68465C23.5568 4.65156 24.2993 4.80576 24.9707 5.13321C25.642 5.46067 26.2209 5.95099 26.6547 6.55954H26.7063V0L29.2861 0.0206604ZM26.6753 11.3836C26.6753 8.57389 25.3699 6.84878 23.2493 6.84878C20.9842 6.84878 19.7768 8.91478 19.7768 11.3836C19.7768 13.8525 20.9842 15.9185 23.2493 15.9185C25.3854 15.8978 26.6753 14.1469 26.6753 11.363V11.3836Z"
                    fill="white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75, delay:0.3 }}
                    color={color}
                    d="M32.862 5.05151L35.4418 14.6171H35.4934L38.0732 5.05151H40.6118L43.1916 14.6171H43.2432L45.7766 5.05151H48.5112L44.6724 17.6489H42.0565L39.3167 8.03171H39.2703L36.5872 17.6489H34.0074L30.1428 5.05151H32.862Z"
                    fill="white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75, delay:0.4 }}
                    color={color}
                    d="M54.6253 18.0207C50.6885 18.0207 48.2996 15.2832 48.2996 11.363C48.2996 7.44277 50.6885 4.70532 54.6253 4.70532C58.5621 4.70532 60.9562 7.44277 60.9562 11.363C60.9562 15.2832 58.5673 18.0207 54.6253 18.0207ZM54.6253 15.8979C57.0916 15.8979 58.3712 13.9507 58.3712 11.363C58.3712 8.77534 57.0916 6.82813 54.6253 6.82813C52.159 6.82813 50.8845 8.74951 50.8845 11.363C50.8845 13.9765 52.1641 15.8979 54.6253 15.8979Z"
                    fill="white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75 , delay:0.5}}
                    color={color}
                    d="M68.0611 18.0207C64.1191 18.0207 61.7302 15.2832 61.7302 11.363C61.7302 7.44277 64.1191 4.70532 68.0611 4.70532C72.0031 4.70532 74.4023 7.44277 74.4023 11.363C74.4023 15.2832 72.0031 18.0207 68.0611 18.0207ZM68.0611 15.8979C70.5274 15.8979 71.807 13.9507 71.807 11.363C71.807 8.77534 70.5274 6.82813 68.0611 6.82813C65.5948 6.82813 64.3204 8.74951 64.3204 11.363C64.3204 13.9765 65.6 15.8979 68.0611 15.8979Z"
                    fill=" white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75, delay:0.6 }}
                    color={color}
                    d="M82.957 4.81394V7.25182H82.9054C80.4184 6.83345 78.4216 8.51208 78.4216 11.1979V17.6335H75.8418V5.05153H78.4216V7.39644H78.4732C79.1388 5.84694 80.1708 4.73647 81.8218 4.73647C82.2016 4.73417 82.581 4.76007 82.957 4.81394Z"
                    fill="white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75 , delay:0.7}}
                    color={color}
                    d="M86.5327 0.020752V10.1028L91.6562 5.05146H94.8346L90.0567 9.7361L95.3505 17.6489H92.2547L88.287 11.4509L86.5378 13.1037V17.6644H83.958V0.020752H86.5327Z"
                    fill="white"
                  />
                  <Path
                  initial={{opacity:0,  y:-20}}
                  animate={{ opacity: 1, y :0}}
                  transition={{ type: "spring", duration: 0.75, delay:0.8 }}
                    color={color}
                    d="M106 14.1107C106 16.5279 103.936 18.0309 100.877 18.0309C96.8107 18.0309 95.0616 16.1302 94.9377 13.4702H97.4505C97.5743 14.8235 98.1677 15.9856 100.825 15.9856C102.626 15.9856 103.405 15.0972 103.405 14.307C103.405 12.7265 101.903 12.6077 100.056 12.1635C98.0593 11.6935 95.3505 11.3009 95.3505 8.43954C95.3505 6.37354 97.1719 4.71558 100.324 4.71558C103.75 4.71558 105.376 6.56464 105.546 8.75977H103.033C102.863 7.77325 102.368 6.76092 100.35 6.76092C98.8023 6.76092 97.9613 7.35489 97.9613 8.24327C97.9613 9.52419 99.3647 9.62232 101.186 10.0407C103.487 10.5727 106 11.0892 106 14.1107Z"
                    fill="white"
                  />
                </svg>
              
    </Anchor>
  );
}
