import React,{useContext} from "react";
import { Information } from "../organisms/Information";
import { Footer } from "../molecules/Footer";
import { SeparatorBrands } from "../molecules/SeparatorBrands.js";
import { DigitalBusinessTemplate } from "../templates/DigitalBusinessTemplate";
import { DropwDownInfo } from "../organisms/DropsDownInfo";
import { Global } from "../../contexts/Global";
import { DigitalBusinessData } from "../../services/digitalBusinesData";
import {Helmet} from "react-helmet"


export function DigitalBusiness(props) {
  const {i18n} = useContext(Global);
  const {color,dataPrincipal, dataSecond, dropDownCreateData, dropDownLegalAdvisoryData} = DigitalBusinessData();

  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Construimos Stratups e invertimos capital en el talento real humano."
    />
    <title>Digital Business | Consultora de negocio digital y tecnología</title>
    </Helmet>

    <DigitalBusinessTemplate 
            buildStartups={<Information
            title={dataPrincipal.title}
            description={dataPrincipal.description}
            word={dataPrincipal.word}
            reversed={dataPrincipal.reversed}
            color={color}
            FiguresDetail={dataPrincipal.figuresDetail}
          
          >  </Information>}
          image={<SeparatorBrands></SeparatorBrands>}
          completeCover={<Information
            title={dataSecond.title}
            description={dataSecond.description}
            word={dataSecond.word}
            reversed={dataSecond.reversed}
            color={color}
            
             
          ></Information>
        }
        dropInfoCreate={ <DropwDownInfo 
          color={color} 
          titleColor={dropDownCreateData.titleWord}
          title={dropDownCreateData.title}
          info={dropDownCreateData.dropDownInfo}>
          </DropwDownInfo>
        }
        dropInfoLegal={ <DropwDownInfo 
          color={color} 
          titleColor={dropDownLegalAdvisoryData.titleWord}
          title={dropDownLegalAdvisoryData.title}
          info={dropDownLegalAdvisoryData.dropDownInfo}>
          </DropwDownInfo>}
          ></DigitalBusinessTemplate>
      <Footer color={color}></Footer>
    </>
  );
}
