import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const HolderDiv = styled.div`
    width:90px;
    height:90px;
    ${({trans})=> trans}
   @media (min-width: ${Breakpoints.tablet}) {
       
        width:150px;
        height:150px;
        ${({transMd})=> transMd}
    }

    @media (min-width: ${Breakpoints.desktop}) {
       
        width:180px;
        height:180px;
        ${({transXl})=> transXl}
    }

   
   
    
`;