import React from "react";
import { HolderContent } from "../../atoms/HolderContent";
import { HolderCol } from "../../atoms/HolderCol";
import { HomeTemplate} from "../HomeTemplate";

export function AboutUsTemplate({
  buildStartups,
  image,
  
}) {
  return (
    <div className="mt-8 pt-8 bg-grayClear">
      <HomeTemplate noBorder={true} bg={true}>{buildStartups}</HomeTemplate>
      <div className='bg-white py-8'>
      <HolderContent>{image}</HolderContent></div>
     
    </div>
  );
}
