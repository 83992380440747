import React, {useContext} from "react";
import { HeadingM } from "../../atoms/HeadingM";
import { Text } from "../../atoms/Text";
import { Anchor } from "../../atoms/Anchor";
import { Global}  from "../../../contexts/Global"
export function OtherLinks({ title, description }) {
    const {i18n} = useContext(Global);
  return (
    <div>
      <HeadingM weight={'500'}  className='mb-lg-6 mb-4' color={"primary"}>Otros enlaces</HeadingM>
      <Anchor changeColor='#3452FF !important' className='text-blacktext'  to='/politicaprivacidad'>
        <Text fs='24px' >
        {i18n.t("PRIVACY_POLICY")}
        </Text>
      </Anchor>
      <Anchor changeColor='#3452FF !important' className='text-blacktext'  to='/terminosycondiciones'>
        <Text fs='24px'  >
        {i18n.t("TERMS_AND_CONDITIONS")}
        </Text>
      </Anchor>
      <Anchor changeColor='#3452FF !important' className='text-blacktext'  to='/politicacookies'>
        <Text fs='24px' >
        {i18n.t("COOKIES_POLICY")}
        </Text>
      </Anchor>
    </div>
  );
}
