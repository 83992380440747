import React, { useContext } from "react";
import { HomeTemplate } from "../templates/HomeTemplate";
import { Global } from "../../contexts/Global";
import { Information } from "../organisms/Information";
import { Footer } from "../molecules/Footer";
import { HomeData } from "../../services/homeData";
import { Carrousel } from "../molecules/Carousel";
import { Helmet } from "react-helmet";

export function Home(props) {
  const { i18n } = useContext(Global);
  const { data, title } = HomeData();
  return (
    <>
      <Helmet>
        <meta name="description" content="Consultora de negocio digital y tecnología" />
        <title>Dwoorks | Consultora de negocio digital y tecnología</title>
      </Helmet>

      <Carrousel></Carrousel>

      <div>
        {data.map((item, index) => {
          return (
            <HomeTemplate key={index + "infoHome"}>
              <Information
                to={item.to}
                title={item.title}
                description={item.description}
                link={item.link}
                word={item.word}
                reversed={item.reversed}
                color={item.color}
                FiguresDetail={item.figuresDetail}
                triangle={item.triangle}
              ></Information>
            </HomeTemplate>
          );
        })}
      </div>
      <Footer color="primary"></Footer>
    </>
  );
}
