import React from "react";
import { Input,Span } from "./styles";


export function FormLabel({
  label,
  placeholder,
  value,
  onChange,
  required,
  error,
  name, className
}) {
  return (
    <div className={`d-flex flex-column w-100 ${className}`}>
      <label for="basic-url" className="form-label">
        <Span className='mb-0' >{label}</Span>
        {required ? <Span className="text-red">*</Span> : ""}
        {error ? <Span className="text-red"> {error}</Span> : ""}
      </label>
      <div className="input-group mb-3">
        <Input
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          type="text"
          name={name}
          className="w-100 p-3"
          aria-describedby="basic-addon3"
          value={value}
        />
      </div>
    </div>
  );
}
