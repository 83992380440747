import styled from "styled-components";

export const DivBlick = styled.div`

display: flex;
flex-direction: row;
align-items: center;
`;





