import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const HolderDiv = styled.div`
    width:${Breakpoints.mobileWidth};

    @media (min-width: ${Breakpoints.mobile}) {
        width:${Breakpoints.tabletWidth};
            
    }
    @media (min-width: ${Breakpoints.tablet}) {
       
        width:${Breakpoints.desktopWidth};
    }

   
    
`;