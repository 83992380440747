import React from 'react'
import { Styledh3 } from './styles'


export function Heading ({color = 'blackText', children,className,...props}) {
  return (
    <Styledh3 className={` text-${color} ${className} mb-0`} {...props} >
        {children}
    </Styledh3>
  )
}
