import React from 'react'
import { HolderDiv } from './styles'

export function Square ({color}) {
    return (
      <HolderDiv className={`bg-${color} `}>
         
      </HolderDiv>
    )
  }
  