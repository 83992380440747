import React from "react";
import { HolderContent } from "../../atoms/HolderContent";

export function ContactTemplate({text, form, separator}) {
  return (
      <>
    <div  className='mt-8 bg-grayClear py-10'>
      <HolderContent>
        <div className='d-flex justify-content-between flex-lg-row flex-column'>
        <div className='col-12 col-lg-5'>{text}</div>
        <div className='col-12 col-lg-6 mt-7 mt-lg-0'>{form}</div></div>
      </HolderContent>
      
    </div>
    <div className='bg-white py-8'><HolderContent>{separator}</HolderContent></div></>
      
  );
}
