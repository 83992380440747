import React from "react";
import { HolderContent } from "../../atoms/HolderContent";
import { HoldInfo } from "./styles";

export function HomeTemplate({noBorder, children, bg}) {
  return (
    <div className={noBorder ? '': 'border-bottom'}>
      <HolderContent bg={bg}>
        <HoldInfo className="w-100 ">
          <div>{children}</div>{" "}
        </HoldInfo>
      </HolderContent>
    </div>
  );
}
