import React,{useContext} from "react";
import { Information } from "../organisms/Information";
import { Footer } from "../molecules/Footer";
import { DigitalWorkshopTemplate } from "../templates/DigitalWorkshopTemlate";
import { Global } from "../../contexts/Global";
import { DigitalWorkshopData } from "../../services/DigitalWorkshopData";
import { SeparatorBrands } from "../molecules/SeparatorBrands.js";
import {Helmet} from "react-helmet"


export function DigitalWorkshop(props) {
  const {i18n} = useContext(Global);
  const {color,dataPrincipal, dataSecond, dropDownCreateData, dropDownLegalAdvisoryData} = DigitalWorkshopData();

  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Descubre factores y estrategias pensadas para iniciar tu negocio digital."
    />
    <title>Digital workshop | Consultora de negocio digital y tecnología</title>
    </Helmet>

    <DigitalWorkshopTemplate 
            buildStartups={<Information
            title={dataPrincipal.title}
            description={dataPrincipal.description}
            word={dataPrincipal.word}
            reversed={dataPrincipal.reversed}
            color={color}
            FiguresDetail={dataPrincipal.figuresDetail}
          >  </Information>}
          image={<SeparatorBrands></SeparatorBrands>}
         
          ></DigitalWorkshopTemplate>
      <Footer color={color}></Footer>
    </>
  );
}
