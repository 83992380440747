import React, {useContext} from "react";
import { Global } from "../../contexts/Global";
import { Footer } from "../molecules/Footer";
import { TermsAndConditionsTemplate } from "../templates/TermsAndConditionsTemplate";
import { OtherLinks } from "../molecules/OtherLinks";
import { TermsAndConditionsData } from "../../services/TermsAndConditionsData";

export function TermsAndConditions(props) {
  const {i18n} = useContext(Global);
  const data = TermsAndConditionsData();
  return (
      <>
    <TermsAndConditionsTemplate TermsAndConditionsData={data} otherLinks={<OtherLinks/>} tit={i18n.t("TERMS_AND_CONDITIONS")}>
    </TermsAndConditionsTemplate>
    <Footer color='primary'></Footer>
    </>
  );
}
