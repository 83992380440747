import { initializeApp } from "firebase/app";

import { getFirestore, collection, addDoc } from "firebase/firestore/lite";
const firebaseConfig = {
  apiKey: "AIzaSyAPYFy1OcaURuIcAr3HI6id_koI9JzY4UE",
  authDomain: "dwoorks-pro.firebaseapp.com",
  projectId: "dwoorks-pro",
  storageBucket: "dwoorks-pro.appspot.com",
  messagingSenderId: "275727431266",
  appId: "1:275727431266:web:f42e6acd4a49e73a7e833b",
  measurementId: "G-7N16GJ3L4Q"
};
/*const firebaseConfig = {
  apiKey: "AIzaSyAB4aWZGwNpIt2v2_0fKX9CF1-rtdmG0sg",
  authDomain: "dwoorks-dev.firebaseapp.com",
  projectId: "dwoorks-dev",
  storageBucket: "dwoorks-dev.appspot.com",
  messagingSenderId: "476123503747",
  appId: "1:476123503747:web:34b524b7a0ba3388f7cfb6",
  measurementId: "G-H4KZ9XK1JY",
};*/

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export async function saveOnDB({name, surname, email, company}) {
  try {
    const docRef = await addDoc(collection(db, "clients"), {
      name: name,
      surname: surname,
      email: email,
      company: company,
      createAt: Date.now(),
      to: [email, 'alejandropugnaire@dwoorks.com'],
      message:{
        subject:`Hola ${name} ${surname}. ¡Bienvenido a Dwoorks!`,
        html:`<div dir="ltr"><div>¡Hey ${name}!</div><div><br></div>Muchas gracias por ponerte en contacto con nosotros.&nbsp;<div><br></div><div><b>Comprueba que la información que nos has enviado es correcta para que podamos ponernos en contacto contigo.</b></div><div><br></div><div>${name} ${surname}</div><div>${email}</div><div>${company}<br><div><br></div><div>¡Muchas gracias!</div><div><br></div><div><br></div><div><br></div><div><br></div><div>-<br></div><div><div dir="ltr"><div dir="ltr"><div><span style="font-family:arial,sans-serif"><img src="https://ci4.googleusercontent.com/proxy/AlnlYWHd6cIrhJ9stEAvAoc6hKE1a9RFQ6YLsBXL5q9aPLuRAtYu8RlnyZJo88gsxnQnfqB9bvW6znomeeH0OEq9Vf4BH-9Gey3Qo73dfr8rdXJZeVpiJinYOwuBizxbcPigfcMYwvQHnlBPJ1fESPJKpbQJbrRvAefIWMKqszFJv9p944yOFSLKOM1_EInZeLk_GuBbYUtPLWlHdA=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1Efmd-kV8k6R302Oc1lXCRUdw7ludY84V&amp;revid=0B2jQi85B0sySYjBvS1huaXIrYmMwN2pvQ3dudTlkRDBXL0JBPQ" width="96" height="16" class="CToWUd"><br></span></div><div><font face="arial, sans-serif">-</font></div><div><font face="arial, sans-serif"><a href="http://www.dwoorks.com/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.dwoorks.com/&amp;source=gmail&amp;ust=1631878629696000&amp;usg=AFQjCNE4k553YdMEa8ebNdhHkBk4IwpmYQ">www.dwoorks.com</a>&nbsp;| +34 93 744 00 55</font></div><div><font face="arial, sans-serif"><br></font></div><div><i style="color:rgb(153,153,153);font-family:arial,sans-serif">"Inspiramos a nuestros partners llevando más allá la visión de su proyecto. Porque trabajamos para el mañana."</i></div><div><i style="color:rgb(153,153,153);font-family:arial,sans-serif"><br></i></div><div><i style="color:rgb(153,153,153);font-family:arial,sans-serif"><br></i></div></div></div></div></div></div>`
      }
    });
    console.log("Document written");
    return true;
  } catch (e) {
    console.error("Error adding document: ", e);
    return false;
  }
}
