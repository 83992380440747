import React from "react";
import { Image } from "../Image";
import { motion } from "framer-motion";
import { HolderContent } from "../HolderContent";
import { Logo } from "../Logo";
import { Anchor } from "../Anchor";
export function HeaderHolder({ image, text, onClick }) {
  return (
    <motion.div
      key={"header"}
      initial={{ y: "-100vh" }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: "-100vh" }}
      transition={{ type: "smooth", duration: 0.5 }}
      onClick={onClick}
      className="position-fixed fixed-top w-100 vh-100 bg-white pt-6  d-flex flex-column flex-xxl-row "
    >
      <HolderContent className="h-100">
        <div
          className="d-flex flex-column w-100 justify-content-xxl-between h-100"
          style={{ overflowX: "hidden", overflowY: "auto" }}
        >
          <div className="align-items-center justify-content-between d-flex">
            <Logo color="black"></Logo>
            <motion.svg
              key="cross"
              initial={{ opacity: 0 }}
              animate={{ rotateZ: 45, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              exit={{ rotateZ: 45, opacity: 1 }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="11.5195" width="1.92" height="24" rx="0.96" fill="#070928" />
              <rect
                y="12.48"
                width="1.92"
                height="24"
                rx="0.96"
                transform="rotate(-90 0 12.48)"
                fill="#070928"
              />
            </motion.svg>
          </div>
          <div className="d-flex flex-row justify-content-between mt-8 mt-xxl-0 align-items-xxl-center h-100">
            <div className="col-12 col-xxl-7 d-none d-xxl-flex ">
              <Image deskHeigth={"500px"} image={image} alt="portada" />
            </div>
            <div className="col-12 col-xxl-4 ">{text}</div>
          </div>
        </div>
      </HolderContent>
    </motion.div>
  );
}
