import React, { useContext } from "react";
import { Information } from "../organisms/Information";
import { Footer } from "../molecules/Footer";

import { SeparatorBrands } from "../molecules/SeparatorBrands.js";
import { TechnologyTemplate } from "../templates/TechnologyTemplate";
import { DropwDownInfo } from "../organisms/DropsDownInfo";
import { Global } from "../../contexts/Global";
import { TechnologyData } from "../../services/technologyData";
import {Helmet} from "react-helmet"

export function Technology(props) {
  const { i18n } = useContext(Global);
  const {
    color,
    dataPrincipal,
    dataSecond,
    dropDownWeGuide,
    dropDownWeBuild,
  } = TechnologyData();

  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Expertos en desarrollo de software a medida y consultoría tech."
    />
    <title>Technology | Consultora de negocio digital y tecnología</title>
    </Helmet>
      <TechnologyTemplate
        infoWDetail={
          <Information
            title={dataPrincipal.title}
            description={dataPrincipal.description}
            word={dataPrincipal.word}
            reversed={dataPrincipal.reversed}
            color={color}
            FiguresDetail={dataPrincipal.figuresDetail}
            
          >
          </Information>
        }
        imageOne={<SeparatorBrands></SeparatorBrands>}
        info={
          <Information
            title={dataSecond.title}
            description={dataSecond.description}
            word={dataSecond.word}
            reversed={dataSecond.reversed}
            color={color}
            
          ></Information>
        }
        
        dropInfoGuide={
          <DropwDownInfo
            color={color}
            titleColor={dropDownWeGuide.titleWord}
            title={dropDownWeGuide.title}
            info={dropDownWeGuide.dropDownInfo}
          ></DropwDownInfo>
        }
        dropInfoBuild={
          <DropwDownInfo
            color={color}
            titleColor={dropDownWeBuild.titleWord}
            title={dropDownWeBuild.title}
            info={dropDownWeBuild.dropDownInfo}
          ></DropwDownInfo>
        }
       
      ></TechnologyTemplate>
      <Footer color={color}></Footer>
    </>
  );
}
