import styled from "styled-components";
import { Link } from "react-router-dom";

export const Span = styled(Link)`
     font-size: 16px;
     text-decoration:none;
     font-weight: 500;
     &:hover{
          color:${({color='blacktext'})=>`var(--bs-${color}) !important`};
          text-decoration: ${({underline=true})=>underline?'underline': ''};
     }
`;