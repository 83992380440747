import styled from "styled-components";
import { motion } from "framer-motion";

export const Opacity = styled.div`

    background-color: rgba(255, 255, 255, ${({opacity,fix})=>fix? 1 :opacity});
`;


export const Path = styled(motion.path)`
  
    fill: ${({color})=>color};
    
`;

export const Rect = styled.rect`

    fill: ${({color})=>color};
    
`;

export const MenuButton = styled.div`
    cursor:pointer;
    
`;
export const SideText = styled.div`
    position: absolute;
    top:0px;
    left:0px;
    color:red;
    z-index:70;
`;



