import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const Div = styled.div`
    --size-sm: 90px;
    --trans-sm: -45px;
    --size-md: 150px;
    --trans-md: -75px;
    --size-xl: 180px;
    --trans-xl: -90px;

    width: var(--size-sm);
    height:var(--size-sm);
    border-radius: 50%;
    opacity: ${({opacity})=> opacity};

   @media (min-width: ${Breakpoints.tablet}) {
       
        width:var(--size-md);
        height:var(--size-md);
    }

    @media (min-width: ${Breakpoints.desktop}) {
        width:var(--size-xl);
        height:var(--size-xl);
    }

   
   
    
`;