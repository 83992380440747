import React from "react";
import { HolderContent } from "../../atoms/HolderContent";
import { HolderCol } from "../../atoms/HolderCol";
import { HomeTemplate } from "../HomeTemplate";

export function TechnologyTemplate({
  infoWDetail,
  imageOne,
  info,
  dropInfoGuide,
  dropInfoBuild,
}) {
  return (
    <div className="mt-8 pt-8 bg-grayClear">
      <HomeTemplate noBorder={true} bg={true}>{infoWDetail}</HomeTemplate>
      <div className='bg-white py-8'>
      <HolderContent>{imageOne}</HolderContent></div>
      <div className="mt-9 mt-lg-10 mb-8 mb-lg-9 ">
        <HolderContent  >{info} </HolderContent>
      </div>
      <HolderContent >
        <div className="d-flex flex-column flex-lg-row justify-content-between pb-9 pb-lg-10 ">
          <HolderCol >{dropInfoGuide}</HolderCol>
          <HolderCol className='mt-8 mt-lg-0'>{dropInfoBuild}</HolderCol>
        </div>
      </HolderContent>
    </div>
  );
}
