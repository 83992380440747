import React, { useState } from "react";
import { Text } from "../../atoms/Text";
import { motion, AnimatePresence } from "framer-motion";
import {DivBlick} from "./styles";
export function TextDropDown({ title, description }) {
  const [showDescription, setShowDescription] = useState(true);

  const variantVertical = {

    rotate: {rotateZ:[0,90], transition:{duration:0.5}},
    reverseRotation: {rotateZ:[90,0], transition:{duration:0.5}},
    

  }
  const varialHoritzontal = {
    vanish: {opacity:[1,0], transition:{duration:0.2}},
    reverseVanish: {opacity:[0,1], transition:{duration:0.5}}
  }

  function handleShow() {
    setShowDescription(!showDescription);
  }
  return (
    <>
      <div
        onClick={() => handleShow()}
        className="d-flex justify-content-between align-items-center py-3 cursor-pointer"
      >
        <Text fs="24px"  fsMobile="20px" className="mb-0">
          {title}
        </Text>
        <DivBlick >
          <AnimatePresence>
            <motion.svg
              initial={{ x: "50%" }}
              variants={variantVertical}
              animate={showDescription ? 'rotate': 'reverseRotation'}
              width="3"
              height="24"
              viewBox="0 0 3 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.52002"
                width="1.92"
                height="24"
                rx="0.96"
                fill="#070928"
              />
            </motion.svg>
            <motion.svg
              initial={{ x: "-50%" }}
              variants={varialHoritzontal}
              animate={showDescription ? 'vanish': 'reverseVanish'}
              width="24"
              height="3"
              viewBox="0 0 24 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="2.47998"
                width="1.92"
                height="24"
                rx="0.96"
                transform="rotate(-90 0 2.47998)"
                fill="#070928"
              />
            </motion.svg>
          </AnimatePresence>
        </DivBlick>
      </div>
      <AnimatePresence>
        {showDescription && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: [0, 0, 1]}}
            transition={{ duration: 0.5 }}
            exit={{ height:  0, opacity: [1,0,0]}}
          >
            <Text fs="18px" className="text-graytext m-0 pb-4">
              {description}
            </Text>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
