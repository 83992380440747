
import { StyledLink } from './styles'
//import './styles.css'


export function Anchor (props) {
  return (
    <StyledLink {...props} >
        {props.children}
    </StyledLink>
  )
}
