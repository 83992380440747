export const esES = {
    GO_FAST:'Si quieres ir rápido puedes ir solo, pero si quieres llegar lejos,',
    KNOW_US:'Conócenos',
    DISCOVER_MORE:'Descubre más',
    INSPIRE_WITH:'Inspírate con proyectos y workshops',
    // LEGAL
    DWOORKS:'dwoorks',
    PRIVACY_POLICY:'Política de privacidad',
    TERMS_AND_CONDITIONS: 'Términos y condiciones',
    COOKIES_POLICY: 'Política de cookies',
    // HOME TITLE
    HOME_TITLE:'Impulsando y creando Startups desde Barcelona',
    HOME_DESC: 'Inspiramos a nuestros partners llevando más allá la visión de su proyecto. Porque trabajamos para el mañana.',
        //Home -part 1
        CONTACTING:'Contactar',
    US:'Nosotros',
    APORT_VALUE_FUTURE_TITLE: 'Aportamos valor al futuro del planeta. Somos una empresa innovadora.',
    APORT_VALUE_FUTURE_DESC:"Inspiramos a nuestros partners llevando más allá la visión de su proyecto. Porque trabajamos para el mañana. Construimos ideas del futuro que ayudán a mejorar y cambiar el mundo del mañana.",
    EXPLORE_OUR_SERVICES: "Explorar nuestros servicios",
    // Home part 2
    TECH: "Tecnología",
    EXPERIENCE_TECH_TITLE: "Experiencia, tecnología, diseño y eficiencia a tu servicio.",
    EXPERIENCE_TECH_DESC: "Inspiramos a nuestros partners llevando más allá la visión de su proyecto. Porque trabajamos para el mañana. Construimos ideas del futuro que ayudán a mejorar y cambiar el mundo del mañana.",
    //HOME part 3
    DIGITAL_BUSINESS: "Negocio digital",
    WE_BUILD_STARTUPS_TITLE:"Construimos Startups e invertimos capital en el talento real humano.",
    WE_BUILD_STARTUPS_DESC:"Invertimos en compañías incipientes en fase de desarrollo de producto, que busquen valor añadido no solo a través de inyecciones de capital sino a través de un acompañamiento y un background basado en nuestra experiencia.",
    // HOME part 4
    DIGITAL_WORKSHOP: "Digital workshops",
    DIGITAL_WORKSHOP_TITLE:"Descubre factores y estrategias pensadas para iniciar tu negocio digital.",
    DIGITAL_WORKSHOP_DESC:"Descubre proyectos digitales en los que hemos participado. También las startups que tenemos en desarrollo y los emprendedores con los que colaboramos. Descubrenos junto a nuestros partners y proyectos.",
    //-------------NEGOCIO DIGITAL-------------------
    DIGITAL_BUSINESS_PRE_TITLE:"Te ofrecemos",
    DIGITAL_BUSINESS_TITLE:"Cobertura completa para crear tu negocio digital en formato Startup.",
    DIGITAL_BUSINESS_DESC:"Las startups tienen necesidades muy diferentes en cada una de sus fases. Nosotros ofrecemos apoyo inicial para validar la idea, crear el producto, capitalización de la compañía y salir al mercado.",
    DIGITAL_BUSINESS_CREATE:"Construimos ",
    DIGITAL_BUSINESS_CREATE_TITLE:"juntos el negocio digital que has pensado.",
    MINIMUM_VIABLE_PRODUCT:"Minimum Viable Product",
    PROJECT_STRATEGY:"Estrategia de proyecto",
    SUPORT_AND_GEST:"Soporte",
    MINIMUM_VIABLE_PRODUCT_DESC:"Creamos tu asset digital para conseguir tus primeros clientes o implementar una solución dedicada para negocios al más estilo SaaS B2B.",
    PROJECT_STRATEGY_DESC:"A veces la parte más difícil es entender donde empezamos y hacia donde queremos ir. Te ayudamos en planificar tu roadmap de producto, siempre alineado con tu proyecto. Además, estaremos contigo en todas las fases de scale up y growth.",
    SUPORT_AND_GEST_DESC:"Cuando sales al mercado, siempre surgen dudas, problemas, cambios de rumbo y cosas impredecibles. En el momento que el negocio este online, nosotros también lo estaremos.",
    DIGITAL_BUSINESS_LEGAL_ADVISORY:"Asesoramiento y fundrasing ",
    DIGITAL_BUSINESS_LEGAL_ADVISORY_TITLE:"a través de nuestros partners.",
    CONSTITUTION_AND_PACT:"Constitución y pacto de socios",
    CONSTITUTION_AND_PACT_DESC:"Alieamos nuestra experiencia en startups con partners especializados en confeccionar toda la estructura legal necesaria para que tu proyecto se encuentre a salvo.",
    FINANCING_ROUNDS:"Rondas de financiación",
    FINANCING_ROUNDS_DESC:"Te ayudamos a confeccionar toda la información necesaria para captar inversión y te asesoraremos en como ofrecer un enfoque atractivo para posibles inversores. ",
    INVERST_OPORTINITIES:"Oportunidades de inversión",
    INVERST_OPORTINITIES_DESC:"Desde nuestro vehículo de inversión ofreceremos a nuestros partners y board members las oportunidades que consideremos más atractivas. También te brindaremos acceso a financiación publica, enisas y otros.",

    // ---------- TECH------------- //
 
    TECH_FIRST_TAG:`Tecnología a tu servicio`,
    TECH_FIRST_TITLE:`Expertos en desarrollo de software a medida y consultoría tech.`,
    TECH_FIRST_DESCRIPTION:`Construimos proyectos a medida con tecnologías punteras. A través de nuestra consultoría estrategia en producto digital, diseño y experiencia de usuario, creamos soluciones tecnológicas diferenciales.`,
    
    TECH_SECOND_TAG:`Te ofrecemos`,
    TECH_SECOND_TITLE:`Expertos en todos los aspectos del ciclo de vida del producto.`,
    TECH_SECOND_DESCRIPTION:`Cubrimos todas las necesidades que existen para desarrollar un producto digital de éxito. Consultoría estratégica, consultoría de producto, consultoría de diseño, diseño ux/ui, desarrollo, scale up y soporte continuado.`,

    //TECH_THIRD_TAG:`Productos digitales`,
    //TECH_THIRD_TITLE:`Lo que podemos hacer por ti y mucho más.`,
    //TECH_THIRD_DESCRIPTION:`Si no estas familiarizado con lo que son los productos digitales, no te preocupes. Te dejamos una lista de las soluciones mas habituales en las que trabajamos de forma continuada.`,

    TECH_DROP_GUIDE_WORD:`Consultoría tecnología`,
    TECH_DROP_GUIDE_TITLE:` a tú servicio y a la de tu negocio.`,
    TECH_DROP_GUIDE_ONE_TITLE:`Consultoría de producto`,
    TECH_DROP_GUIDE_ONE_DESC:`Te acompañamos en las decisiones más importantes para crear tu producto. Propuesta de valor, funcionalidades, oportunidad, interacción, experiencia y los procesos de toma de decisiones.`,
    TECH_DROP_GUIDE_TWO_TITLE:`Consultoría de diseño`,
    TECH_DROP_GUIDE_TWO_DESC:`Lo más difícil es encontrar el camino idóneo. Te ayudamos con la conceptualización estratégica de tu proyecto, alineándolo con tus necesidades y desarrollando para tí un lenguaje visual que te ayude en las fases de scale-up y growth.`,
    TECH_DROP_GUIDE_THEE_TITLE:`Consultoría CRO`,
    TECH_DROP_GUIDE_THEE_DESC:`El objetivo es mejorar las conversiones en tu producto digital. Por eso centraremos nuestros esfuerzos en conseguir que el tráfico que visite tu sitio cumpla con su cometido.`,

    TECH_DROP_BUILD_WORD:`Desarrollo de software`,
    TECH_DROP_BUILD_TITLE:` a medida y diseño estratégico.`,
    TECH_DROP_BUILD_ONE_TITLE:`Desarollo de aplicaciones web`,
    TECH_DROP_BUILD_ONE_DESC:`Desarrollamos aplicaciones y páginas web. Planificamos, diseñamos y desarrollamos productos experienciales con identidad propia. Apostamos por lo diferente.`,
    TECH_DROP_BUILD_TWO_TITLE:`Desarrollo de aplicaciones móviles`,
    TECH_DROP_BUILD_TWO_DESC:`Diseño, experiencia, interactividad y tecnología. Son nuestros pilares fundamentales para desarrollar aplicaciones móviles. Un producto wereable atractivo y que aporte valor.`,
    TECH_DROP_BUILD_THREE_TITLE:`Diseño UX/UI`,
    TECH_DROP_BUILD_THREE_DESC:`Diseñamos experiencias personalizadas a través del uso de un producto digital. También diseñamos sistemas de diseño y kits atómicos para construir productos estandarizados y escalables desde el principio.`,

   /*TECH_DROP_DIGITAL_TITLE:`Productos digitales`,
    TECH_DROP_DIGITAL_DESC:`y soluciones de negocio online.`,
    TECH_DROP_DIGITAL_ONE_TITLE:`Desarrollo de aplicaciones móviles`,
    TECH_DROP_DIGITAL_ONE_DESC:`Creamos aplicaciones móviles con diseño y funcionalidades personalizadas.`,
    TECH_DROP_DIGITAL_TWO_TITLE:`Desarrollo de aplicaciones web`,
    TECH_DROP_DIGITAL_TWO_DESC:`Construimos aplicaciones web y de escritorio compatibles con los principales navegadores y sistemas operativos.`,
    TECH_DROP_DIGITAL_THREE_TITLE:`Desarrollo de software a medida`,
    TECH_DROP_DIGITAL_THREE_DESC:`Desarrollamos soluciones de software a medida y extensiones compatibles con productos ya en uso.`,
    TECH_DROP_DIGITAL_FOUR_TITLE:`Tecnologías que usamos`,
    TECH_DROP_DIGITAL_FOUR_DESC:`Somos expertos en React.js, React Native, Node.js, MongoDB, Google Cloud Platform, Firebase entre otros.`,
    TECH_DROP_DIGITAL_FIVE_TITLE:`Partners tecnológicos`,
    TECH_DROP_DIGITAL_FIVE_DESC:`Trabajamos todos nuestros proyectos con Google Cloud como principal partner en soluciones de servicios, datos y cloud entre otros.`,*/

    // ABOUT US---------
    ABOUT_US_TITLE:'No somos una consultora tradicional. Somos tu partner estratégico.',
    ABOUT_US_DESC:'Aportamos un valor diferencial porque solo participamos en proyectos en los que confiamos. Vivimos rodeados de emprendimiento, innovación, tecnología y startups. Apostamos por un futuro digital y conectado.',

    // FORM ---
    NAME: 'Nombre',
    NAME_PLACE: '¿Cómo te llamas?',
    SURNAME: 'Apellidos',
    SURNAME_PLACE: '¿Cuál es tu apellido?',
    EMAIL: 'Correo electrónico',
    EMAIL_PLACE: '¿Tienes correo electrónico de empresa o personal?',
    COMPANY:'Empresa',
    COMPANY_PLACE: '¿Desde dónde nos contactas?',
    SEND_FORM:'Enviar formulario de contacto',

    CONTACT_TITLE:'¿Conectamos? Cuéntanos tu idea y hagámosla realidad juntos.',
    CONTACT_OFICINE: 'Oficina Barcelona',
    CONTACT_MAIL:'barcelona@dwoorks.com',
    CONTACT_NUM:'+34 93 744 00 55',

    CONTACT_SUCCESS_TITLE: 'Tu solicitud se ha enviado correctamente.',
    CONTACT_SUCCESS_DESC:'Gracias por ponerte en contacto con nosotros. Habrás recibido un email en tu bandeja de entrada. Si no lo has recibido puede que esté en Spam.',
    CONTACT_ERROR_TITLE:'¡Ops! Parece que algo ha ocurrido.',
    CONTACT_ERROR_DESC:'No se ha podido procesar tu solicitud. Comprueba que has rellenado la información correctamente.',

    // ---------------- FOOTER ----------

    HOME: 'Pagina principal',
    ABOUT_US: 'Sobre nosotros',
    DISCOVER: 'Descubre',
    INSPIRE: 'Inspira',
    CONNECT:'Conecta',
    PROJECTS:'Proyectos',
    STUDY_CASES:'Casos de estudio',
    CAPITAL_AND_FIN: 'Capital y financiación',
    CONTACT:'Contacto',
    FOOTER_TEXT:'Más allá de una pasión. Una forma de vivir. Construimos el futuro tecnológico de las personas y las empresas.',

    OTHER_LINKS: 'Otros enlaces',
    WELCOME_TO_DIGITAL_ERA: 'Bienvenido a la era del negocio digital.',
    
 // Cookies Policy
    COOKIES_POLICY_TITLE:'Política de cookies',
    COOKIES_POLICY_DESC:`El sitio web utiliza cookies para ayudar a personalizar tu experiencia en línea. Al acceder a https://www.dwoorks.com , aceptaste utilizar las cookies necesarias.

        Una cookie es un archivo de texto que un servidor de páginas web coloca en tu disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a tu computadora. Las cookies se te asignan de forma exclusiva y solo un servidor web en el dominio que emitió la cookie puede leerlas.
    
        Podemos utilizar cookies para recopilar, almacenar y rastrear información con fines estadísticos o de marketing para operar nuestro sitio web. Tienes la capacidad de aceptar o rechazar cookies opcionales. Hay algunas cookies obligatorias que son necesarias para el funcionamiento de nuestro sitio web. Estas cookies no requieren tu consentimiento, ya que siempre funcionan. Ten en cuenta que al aceptar las cookies requeridas, también aceptas las cookies de terceros, que podrían usarse a través de servicios proporcionados por terceros si utilizas dichos servicios en nuestro sitio web, por ejemplo, una ventana de visualización de video proporcionada por terceros e integrada en nuestro sitio web.`,

    
//-----_PRivacy Policy
    PRIVACY_POLICY_TITLE:`Política de privacidad`,
    PRIVACY_POLICY_DESC:`El sitio web http://www.dwoorks.com  es propiedad de Digital Works Solutions S.L, que es un controlador de datos de tus datos personales.

    Hemos adoptado esta Política de privacidad, que determina cómo procesamos la información recopilada por http://www.dwoorks.com , que también proporciona las razones por las que debemos recopilar ciertos datos personales sobre ti. Por lo tanto, debes leer esta Política de privacidad antes de usar el sitio web de http://www.dwoorks.com .
    
    Cuidamos tus datos personales y nos comprometemos a garantizar su confidencialidad y seguridad.
    
    `,
    PRIVACY_POLICY_TITLE_ONE:`Información personal que recopilamos:`,
    PRIVACY_POLICY_DESC_ONE:`Cuando visitas http://www.dwoorks.com , recopilamos automáticamente cierta información sobre tu dispositivo, incluida información sobre tu navegador web, dirección IP, zona horaria y algunas de las cookies instaladas en tu dispositivo. Además, a medida que navegas por el sitio, recopilamos información sobre las páginas web individuales o los productos que ves, qué sitios web o términos de búsqueda te remitieron al sitio y cómo interactúas con él. Nos referimos a esta información recopilada automáticamente como "Información del dispositivo". Además, podemos recopilar los datos personales que nos proporcionas (incluidos, entre otros, nombre, apellido, dirección, información de pago, etc.) durante el registro para poder cumplir con el acuerdo.`,
    PRIVACY_POLICY_TITLE_TWO:`¿Por qué procesamos tus datos?`,
    PRIVACY_POLICY_DESC_TWO:`Nuestra máxima prioridad es la seguridad de los datos del cliente y, como tal, podemos procesar solo los datos mínimos del usuario, solo en la medida en que sea absolutamente necesario para mantener el sitio web. La información recopilada automáticamente se utiliza solo para identificar casos potenciales de abuso y establecer información estadística sobre el uso del sitio web. Esta información estadística no se agrega de tal manera que identifique a ningún usuario en particular del sistema.

    Puedes visitar el sitio sin decirnos quién eres ni revelar ninguna información por la cual alguien pueda identificarte como una persona específica. Sin embargo, si deseas utilizar algunas de las funciones del sitio web, o deseas recibir nuestro boletín informativo o proporcionar otros detalles al completar un formulario, puedes proporcionarnos datos personales, como tu correo electrónico, nombre, apellido, ciudad de residencia, organización y número de teléfono. Puedes optar por no proporcionar tus datos personales, pero es posible que no puedas aprovechar algunas de las funciones del sitio web. Por ejemplo, no podrás recibir nuestro boletín ni contactarnos directamente desde el sitio web. Los usuarios que no estén seguros de qué información es obligatoria pueden ponerse en contacto con nosotros a través de hello@dwoorks.com.
    
    `,
    PRIVACY_POLICY_TITLE_THREE:`Tus derechos:`,
    PRIVACY_POLICY_DESC_THREE:`Si eres residente europeo, tienes los siguientes derechos relacionados con tus datos personales:

    El derecho a ser informado.
    
    El derecho de acceso.
    
    El derecho a la rectificación.
    
    El derecho a borrar.
    
    El derecho a restringir el procesamiento.
    
    El derecho a la portabilidad de datos.
    
    El derecho a oponerte.
    
    Derechos en relación con la toma de decisiones automatizada y la elaboración de perfiles.
    
    Si deseas ejercer este derecho, comunícate con nosotros a través de la información de contacto a continuación.
    
    Además, si eres residente europeo, destacamos que estamos procesando tu información para cumplir con los contratos que podríamos tener contigo (por ejemplo, si realizas un pedido a través del sitio), o de otra manera para seguir nuestros intereses comerciales legítimos enumerados anteriormente. Además, ten en cuenta que tu información puede transferirse fuera de Europa, incluidos Canadá y Estados Unidos.`,
    PRIVACY_POLICY_TITLE_FOUR:`Enlaces a otros sitios web:`,
    PRIVACY_POLICY_DESC_FOUR:`Nuestro sitio puede contener enlaces a otros sitios web que no son de nuestra propiedad ni están controlados por nosotros. Ten en cuenta que no somos responsables de dichos sitios web ni de las prácticas de privacidad de terceros. Te recomendamos que estés atento cuando abandones nuestro sitio web y leas las declaraciones de privacidad de cada sitio que pueda recopilar información personal.`,
    PRIVACY_POLICY_TITLE_FIVE:`Seguridad de la información:`,
    PRIVACY_POLICY_DESC_FIVE:`Aseguramos la información que proporcionas en servidores informáticos en un entorno controlado y seguro, protegido del acceso, uso o divulgación no autorizados. Mantenemos medidas de seguridad administrativas, técnicas y físicas razonables para proteger contra el acceso no autorizado, el uso, la modificación y la divulgación de datos personales bajo su control y custodia. Sin embargo, no se puede garantizar la transmisión de datos a través de Internet o redes inalámbricas.`,
    PRIVACY_POLICY_TITLE_SIX:`Divulgación legal:`,
    PRIVACY_POLICY_DESC_SIX:`Divulgaremos cualquier información que recopilemos, usemos o recibamos si así lo requiere o lo permite la ley, como para cumplir con una citación o un proceso legal similar, y cuando creemos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger tu seguridad o la seguridad de los demás, investigar el fraude o responder a una solicitud del gobierno.`,
    PRIVACY_POLICY_TITLE_SEVEN:`Información de contacto:`,
    PRIVACY_POLICY_DESC_SEVEN:`Si deseas comunicarte con nosotros para comprender más sobre esta Política o deseas comunicarte con nosotros en relación con cualquier asunto sobre los derechos individuales y tu información personal, puedes enviarnos un correo electrónico a hello@dwoorks.com.`,


    TERMS_AND_CONDITIONS_DESC:`¡Bienvenido a http://www.dwoorks.com !

    Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de Digital Works Solutions S.L, ubicado en http://www.dwoorks.com .
    
    Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes usando http://www.dwoorks.com  si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_ONE:`Cookies:`,
    TERMS_AND_CONDITIONS_DESC_ONE: `El sitio web utiliza cookies para ayudar a personalizar tu experiencia en línea. Al acceder a http://www.dwoorks.com , aceptaste utilizar las cookies necesarias.

    Una cookie es un archivo de texto que un servidor de páginas web coloca en tu disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a tu computadora. Las cookies se te asignan de forma exclusiva y solo un servidor web en el dominio que emitió la cookie puede leerlas.
    
    Podemos utilizar cookies para recopilar, almacenar y rastrear información con fines estadísticos o de marketing para operar nuestro sitio web. Tienes la capacidad de aceptar o rechazar cookies opcionales. Hay algunas cookies obligatorias que son necesarias para el funcionamiento de nuestro sitio web. Estas cookies no requieren tu consentimiento, ya que siempre funcionan. Ten en cuenta que al aceptar las cookies requeridas, también aceptas las cookies de terceros, que podrían usarse a través de servicios proporcionados por terceros si utilizas dichos servicios en nuestro sitio web, por ejemplo, una ventana de visualización de video proporcionada por terceros e integrada en nuestro sitio web.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_TWO:`Licencia:`,
    TERMS_AND_CONDITIONS_DESC_TWO: `A menos que se indique lo contrario, Digital Works Solutions S.L y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en http://www.dwoorks.com . Todos los derechos de propiedad intelectual son reservados. Puedes acceder desde http://www.dwoorks.com  para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.

    No debes:
    
    Copiar o volver a publicar material de http://www.dwoorks.com 
    
    Vender, alquilar o sublicenciar material de http://www.dwoorks.com 
    
    Reproducir, duplicar o copiar material de http://www.dwoorks.com 
    
    Redistribuir contenido de http://www.dwoorks.com 
    
    Este acuerdo comenzará el fecha presente.
    
    Partes de este sitio web ofrecen a los usuarios la oportunidad de publicar e intercambiar opiniones e información en determinadas áreas. Digital Works Solutions S.L no filtra, edita, publica ni revisa los comentarios antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista ni las opiniones de Digital Works Solutions S.L, sus agentes y/o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica. En la medida en que lo permitan las leyes aplicables, Digital Works Solutions S.L no será responsable de los comentarios ni de ninguna responsabilidad, daños o gastos causados ​​o sufridos como resultado de cualquier uso o publicación o apariencia de comentarios en este sitio web.
    
    Digital Works Solutions S.L se reserva el derecho de monitorear todos los comentarios y eliminar los que puedan considerarse inapropiados, ofensivos o que incumplan estos Términos y Condiciones.
    
    Garantizas y declaras que:
    
    Tienes derecho a publicar comentarios en nuestro sitio web y tienes todas las licencias y consentimientos necesarios para hacerlo;
    
    Los comentarios no invaden ningún derecho de propiedad intelectual, incluidos, entre otros, los derechos de autor, patentes o marcas comerciales de terceros;
    
    Los comentarios no contienen ningún material difamatorio, calumnioso, ofensivo, indecente o ilegal de otro modo, que sea una invasión de la privacidad.
    
    Los comentarios no se utilizarán para solicitar o promover negocios o actividades comerciales personalizadas o presentes o actividades ilegales.
    
    Por la presente, otorgas a Digital Works Solutions S.L una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de tus comentarios en todas y cada una de las formas, formatos, o medios.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_THREE:`Hipervínculos a nuestro contenido:`,
    TERMS_AND_CONDITIONS_DESC_THREE: `Las siguientes organizaciones pueden vincularse a nuestro sitio web sin aprobación previa por escrito:

    Agencias gubernamentales;
    
    Motores de búsqueda;
    
    Organizaciones de noticias;
    
    Los distribuidores de directorios en línea pueden vincularse a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otras empresas que figuran en la lista; y
    
    Empresas acreditadas en todo el sistema, excepto que soliciten organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden hacer hipervínculos a nuestro sitio web.
    
    Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones o a otra información del sitio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos y/o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.
    
    Podemos considerar y aprobar otras solicitudes de enlaces de los siguientes tipos de organizaciones:
    
    fuentes de información de consumidores y/o empresas comúnmente conocidas;
    
    sitios de la comunidad .com;
    
    asociaciones u otros grupos que representan organizaciones benéficas;
    
    distribuidores de directorios en línea;
    
    portales de Internet;
    
    firmas de contabilidad, derecho y consultoría; y
    
    instituciones educativas y asociaciones comerciales.
    
    Aprobaremos las solicitudes de enlace de estas organizaciones si: (a) el enlace no nos haría vernos desfavorablemente a nosotros mismos ni a nuestras empresas acreditadas; (b) la organización no tiene registros negativos con nosotros; (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de Digital Works Solutions S.L; y (d) el enlace está en el contexto de información general de recursos.
    
    Estas organizaciones pueden enlazar a nuestra página de inicio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.
    
    Si eres una de las organizaciones enumeradas en el párrafo 2 y estás interesada en vincularte a nuestro sitio web, debes informarnos enviando un correo electrónico a Digital Works Solutions S.L. Incluye tu nombre, el nombre de tu organización, la información de contacto, así como la URL de tu sitio, una lista de las URL desde las que tienes la intención de vincular a nuestro sitio web y una lista de las URL de nuestro sitio a las que te gustaría acceder. Espera 2-3 semanas para recibir una respuesta.
    
    Las organizaciones aprobadas pueden hacer hipervínculos a nuestro sitio web de la siguiente manera:
    
    Mediante el uso de nuestro nombre corporativo; o
    
    Mediante el uso del localizador uniforme de recursos al que se está vinculando; o
    
    Usar cualquier otra descripción de nuestro sitio web al que está vinculado que tenga sentido dentro del contexto y formato del contenido en el sitio de la parte vinculante.
    
    No se permitirá el uso del logotipo de Digital Works Solutions S.L u otro material gráfico para vincular sin un acuerdo de licencia de marca comercial.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_FOUR:`Responsabilidad del contenido:
    `,
    TERMS_AND_CONDITIONS_DESC_FOUR: `No seremos responsables de ningún contenido que aparezca en tu sitio web. Aceptas protegernos y defendernos contra todas las reclamaciones que se presenten en tu sitio web. Ningún enlace(s) debe aparecer en ningún sitio web que pueda interpretarse como difamatorio, obsceno o criminal, o que infrinja, de otra manera viole o defienda la infracción u otra violación de los derechos de terceros.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_FIVE:`Reserva de derechos:`,
    TERMS_AND_CONDITIONS_DESC_FIVE: `Nos reservamos el derecho de solicitar que elimines todos los enlaces o cualquier enlace en particular a nuestro sitio web. Apruebas eliminar de inmediato todos los enlaces a nuestro sitio web cuando se solicite. También nos reservamos el derecho de modificar estos términos y condiciones y su política de enlaces en cualquier momento. Al vincular continuamente a nuestro sitio web, aceptas estar vinculado y seguir estos términos y condiciones de vinculación.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_SIX:`Eliminación de enlaces de nuestro sitio web:`,
    TERMS_AND_CONDITIONS_DESC_SIX: `Si encuentras algún enlace en nuestro sitio que sea ofensivo por cualquier motivo, puedes contactarnos e informarnos en cualquier momento. Consideraremos las solicitudes para eliminar enlaces, pero no estamos obligados a hacerlo ni a responder directamente.

    No nos aseguramos de que la información de este sitio web sea correcta. No garantizamos su integridad o precisión, ni prometemos asegurarnos de que el sitio web permanezca disponible o que el material en el sitio se mantenga actualizado.`,
      
      
    TERMS_AND_CONDITIONS_TITLE_SEVEN:`Exención de responsabilidad:`,
    TERMS_AND_CONDITIONS_DESC_SEVEN: `En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este. Nada en este descargo de responsabilidad:

    limitará o excluirá nuestra responsabilidad o la tuya por muerte o lesiones personales;
    
    limitará o excluirá nuestra responsabilidad o la tuya por fraude o tergiversación fraudulenta;
    
    limitará cualquiera de nuestras responsabilidades o las tuyas de cualquier manera que no esté permitida por la ley aplicable; o
    
    excluirá cualquiera de nuestras responsabilidades o las tuyas que no puedan estar excluidas según la ley aplicable.
    
    Las limitaciones y prohibiciones de responsabilidad establecidas en esta sección y en otras partes de este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) regirá todas las responsabilidades que surjan en virtud de la exención de responsabilidad, incluidas las responsabilidades que surjan en el contrato, en agravio y por incumplimiento de la obligación legal.
    
    Siempre que el sitio web y la información y los servicios en el sitio se proporcionen de forma gratuita, no seremos responsables de ninguna pérdida o daño de cualquier naturaleza.`,

    //CARROUSEL TEXT
    CARROUSEL_TITLE_ONE: 'Impulsando Startups desde Barcelona.',
    CARROUSEL_DESC_ONE: 'Inspiramos a nuestros partners llevando más allá la visión de su proyecto. Porque trabajamos para el mañana.',
    CARROUSEL_LINK_ONE: 'Sobre nosotros',
    CARROUSEL_TITLE_TWO: 'Productos 360º que no dejan indiferente.',
    CARROUSEL_DESC_TWO: 'Lideramos el proyecto desde la idea de negocio hasta la producción del producto multiplataforma.',
    CARROUSEL_LINK_TWO: 'Tecnología',
    CARROUSEL_TITLE_THREE: 'Te asesoramos en todo el camino a recorrer.',
    CARROUSEL_DESC_THREE: 'Brindamos a nuestros clientes el talento creativo, técnico y comercial que necesitan para tener éxito.',
    CARROUSEL_LINK_THREE: 'Negocio digital',
    CARROUSEL_TITLE_FOUR: 'Estrategia como base de tu proyecto.',
    CARROUSEL_DESC_FOUR: 'Trazamos el camino para que, a través de la estrategia y la innovación, sea sencillo impulsar tu negocio digital.',
    CARROUSEL_LINK_FOUR: 'Estrategia',

};
