import React from 'react'
import { Styledh6 } from './styles'


export function HeadingXS ({color = 'white', children,className,...props}) {
  return (
    <Styledh6 className={` text-${color} ${className} mb-0`} {...props} >
        {children}
    </Styledh6>
  )
}
