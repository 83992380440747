import React from 'react'
import { Span } from './styles'


export function DetailColorText ({color = 'primary', children, className,to, underline}) {
  return (
    <Span to={to} className={`text-blacktext ${className}`} color={color} underline={underline} >
        {children}
    </Span>
  )
}
