import React, { useContext } from "react";
import { Global } from "../contexts/Global";

export function TermsAndConditionsData() {
  const { i18n } = useContext(Global);


    const data = [
      {
        title: '',
        description: i18n.t("TERMS_AND_CONDITIONS_DESC")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_ONE"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_ONE")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_TWO"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_TWO")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_THREE"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_THREE")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_FOUR"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_FOUR")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_FIVE"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_FIVE")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_SIX"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_SIX")
      },
      {
        title: i18n.t("TERMS_AND_CONDITIONS_TITLE_SEVEN"),
        description: i18n.t("TERMS_AND_CONDITIONS_DESC_SEVEN")
      },
   
    ];
 
  return data;
}
