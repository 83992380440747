import React from 'react'
import { HolderDiv } from './styles'

export function HolderContent (props) {
    return (
      <div className={`w-100 ${props.bg ? `bg-grayClear`:''}  ${props.className}`} >
          <HolderDiv className='m-auto h-100' >
              {props.children}
          </HolderDiv>
      </div>
    )
  }
  