import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const Styledh3 = styled.h2`
    font-weight: ${({ weight = "400" }) => weight};
    font-size: 34px;
    

    @media (min-width: ${Breakpoints.tablet}) {
        font-size: 56px;
       

    }


`;