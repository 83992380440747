import styled from "styled-components";
import { motion } from "framer-motion";
export const Path = styled(motion.path)`
  
    fill: ${({color})=>color};
    
`;

export const Rect = styled.rect`

    fill: ${({color})=>color};
    
`;


