import React, { useContext } from "react";
import { Global } from "../../../contexts/Global";
import { HolderContent } from "../../atoms/HolderContent";
import { HeadingXS } from "../../atoms/HeadingXS";
import { Anchor } from "../../atoms/Anchor";
import { Text } from "../../atoms/Text";
import { Div } from "./styles";
import { FooterData } from '../../../services/FooterData'

export function Footer({ color }) {
  const data = FooterData();
  const { i18n } = useContext(Global);
  return (
    <HolderContent className={`bg-${color}`}>
      <div className="pt-8 pb-5 d-flex flex-column flex-lg-row border-bottom border-white justify-content-between">
        <Div className=" ">
          <HeadingXS className="py-1">Dwoorks®</HeadingXS>
          <Text className="text-white py-1" fs="14px" fweight="400">
            {i18n.t("FOOTER_TEXT")}
          </Text>
          <Anchor className="text-white" fw="400">
            https://www.dwoorks.com
          </Anchor>
        </Div>

        {data.map((item, index) => {
          return(<Div
            key={"foot" + index}
            className=" mt-7 mt-lg-0 d-flex flex-column"
          >
            <HeadingXS className="py-1">{item.title}</HeadingXS>
            {item.links.map((current, i) => {
              return (<Anchor
                to={current.link}
                key={current.text + i}
                className="text-white py-1"
                fw="400"
              >
                {current.text}
              </Anchor>);
            })}
          </Div>);
        })}

      
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-start align-items-lg-center py-5">
        <Text fs="10px" fsMobile="10px" className="text-white px-0 mt-0 mb-0">
          Dwoorks® | All rights reserved 2021 | Dwoorks is a registered brand,
          and all the information on this website is not allowed to use, copy or
          replicate by any third party.
        </Text>
        <div className="d-flex">
          <Anchor
            to="/politicacookies"
            className="text-nowrap text-white ps-0 pe-3 py-2 px-lg-3"
            fs="10px"
            fw="400"
          >
            {i18n.t("COOKIES_POLICY")}
          </Anchor>
          <Anchor
            to="/politicaprivacidad"
            className=" text-nowrap text-white py-2 px-3"
            fs="10px"
            fw="400"
          >
            {i18n.t("PRIVACY_POLICY")}
          </Anchor>
          <Anchor
            to="/terminosycondiciones"
            className="text-nowrap text-white py-2 ps-3"
            fs="10px"
            fw="400"
          >
            {i18n.t("TERMS_AND_CONDITIONS")}
          </Anchor>
        </div>
      </div>
    </HolderContent>
  );
}
