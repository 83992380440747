import React from "react";

import { Text } from "../../atoms/Text";

export function LegalText({ title, description }) {
  return (
    <div>
      {title && <Text fweight='500' className='mt-5' color='textblack'>{title}</Text>}
      <Text   className="col-10 text-graytext mt-4 mb-0 ">{description}</Text>
    </div>
  );
}
