import React from 'react'
import { Div } from './styles'


export function Triangle ({color = 'primary', opacity,  className, trans}) {
  return (
    <Div opacity={opacity} className={`${className}`} color={color} trans={trans} >
     
    </Div>
  )
}
