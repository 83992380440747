import React from 'react'
import { Parraf } from './styles'

export function Text (props) {
  return (
    <Parraf  className={props.className} fs={props.fs} fsMobile={props.fsMobile}  fweight={props.fweight}  >
        {props.children}
    </Parraf>
  )
}
