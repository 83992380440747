import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const HoldInfo = styled.div`
    padding:120px 0px;


@media (min-width: ${Breakpoints.tablet}) {
   
    padding:160px 0px;
}



`;