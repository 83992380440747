import React, { useContext } from "react";
import { useLocation } from "react-router";
import { HeadingM } from "../../atoms/HeadingM";
import { Text } from "../../atoms/Text";
import { Anchor } from "../../atoms/Anchor";
import { Global } from "../../../contexts/Global";
import { motion } from "framer-motion";
export function HeaderLinks({ title, onClick }) {
  const { i18n } = useContext(Global);
  const location = useLocation();
  return (
    <div onClick={onClick}>
      <HeadingM weight={"500"} className="mb-6" color={"primary"}>
        {i18n.t("WELCOME_TO_DIGITAL_ERA")}
      </HeadingM>
      <Anchor
      color={`${location.pathname === '/' ? '#3452FF !important': '#AAABB4'}`}
        changeColor="#3452FF !important"
        className="text-blacktext"
        to="/"
      >
        <motion.div whileHover={{ x: 10 }} transition={{ type: "smooth" }}>
          <Text fs="28px" fsMobile="24px">
            {i18n.t("HOME")}
          </Text>
        </motion.div>
      </Anchor>
      <Anchor
      color={`${location.pathname === '/sobrenosotros' ? '#3452FF !important': '#AAABB4'}`}
        changeColor="#3452FF !important"
        className="text-blacktext"
        to="/sobrenosotros"
      >
        <motion.div whileHover={{ x: 10 }} transition={{ type: "smooth" }}>
          <Text fs="28px" fsMobile="24px">
            {i18n.t("ABOUT_US")}
          </Text>
        </motion.div>
      </Anchor>
      <Anchor
      color={`${location.pathname === '/tecnologia' ? '#3452FF !important': '#AAABB4'}`}
        changeColor="#3452FF !important"
        className="text-blacktext"
        to="/tecnologia"
      >
        <motion.div whileHover={{ x: 10 }} transition={{ type: "smooth" }}>
          <Text fs="28px" fsMobile="24px">
            {i18n.t("TECH")}
          </Text>
        </motion.div>
      </Anchor>
      <Anchor
      color={`${location.pathname === '/negociodigital' ? '#3452FF !important': '#AAABB4'}`}
        changeColor="#3452FF !important"
        className="text-blacktext"
        to="/negociodigital"
      >
        <motion.div whileHover={{ x: 10 }} transition={{ type: "smooth" }}>
          <Text fs="28px" fsMobile="24px">
            {i18n.t("DIGITAL_BUSINESS")}
          </Text>
        </motion.div>
      </Anchor>
      <Anchor
      color={`${location.pathname === '/digitalworkshop' ? '#3452FF !important': '#AAABB4'}`}
        changeColor="#3452FF !important"
        className="text-blacktext"
        to="/digitalworkshop"
      >
        <motion.div whileHover={{ x: 10 }} transition={{ type: "smooth" }}>
          <Text fs="28px" fsMobile="24px">
            {i18n.t("DIGITAL_WORKSHOP")}
          </Text>
        </motion.div>
      </Anchor>
      <Anchor
              to={"/contacto"}
              underline={false}
              fw='500'
              className={`bg-primary d-inline-flex align-items-center justify-content-center text-white btn px-4 mt-4 d-lg-none`}
              h="48px"
              w='100%'
              fs="16px"
            >
              {i18n.t("CONTACTING")}
            </Anchor>
    </div>
  );
}
