import React, { useState } from "react";
import { Heading } from "../../atoms/Heading";
import { DetailColorText } from "../../atoms/DetailColorText";
import { Text } from "../../atoms/Text";
import { SmallArrow } from "../../atoms/SmallArrow";
import { MarginTopMobile } from "../../atoms/MarginTopMobile";
import { AnimatePresence, motion } from "framer-motion";

export function TextDescription({
  word,
  title,
  description,
  link,
  color,
  detail,
  figure,
  to,
}) {
  const [separation, setSeparation] = useState(false);
  return (
    <>
      {figure && <MarginTopMobile></MarginTopMobile>}
      {detail && <div className="">{detail}</div>}
      <Text className={`text-${color}`} color={color}>
        {word}
      </Text>
      <Heading className="mt-3">{title}</Heading>
      <Text className="col-10 text-graytext mt-6 mb-0">{description}</Text>
      {link && (
        <div
          onMouseEnter={() => setSeparation(true)}
          onMouseLeave={() => setSeparation(false)}
          className="d-flex align-items-center mt-6"
        >
          <DetailColorText underline={false} to={to} color={color}>
            {link}
          </DetailColorText>
          <AnimatePresence>
            {separation && (
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: 8 }}
                exit={{ width: 0 }}
                transition={{ duration: 0.3 }}
              ></motion.div>
            )}
          </AnimatePresence>
          <SmallArrow color={color} />
        </div>
      )}
    </>
  );
}
