import React, { useContext } from "react";
import { Global } from "../contexts/Global";

export function FooterData() {
  const { i18n } = useContext(Global);

  const data = [
    {
      title: i18n.t("DISCOVER"),
      links: [
        {
          text: i18n.t("HOME"),
          link: "/",
        },
        {
          text: i18n.t("ABOUT_US"),
          link: "/sobrenosotros",
        },
        {
          text: i18n.t("TECH"),
          link: "/tecnologia",
        },
        
      ],
    },
    {
      title: i18n.t("INSPIRE"),
      links: [
        {
          text: i18n.t("DIGITAL_BUSINESS"),
          link: "/negociodigital",
        },
        {
          text: i18n.t("DIGITAL_WORKSHOP"),
          link: "/digitalworkshop",
        },
      ],
    },
    {
        title: i18n.t("CONNECT"),
        links: [
          {
            text: i18n.t("CONTACT"),
            link: "/contacto",
          }
        ],
      },
  ];
  return data;
}
