import React, { useContext } from "react";
import { Global } from "../contexts/Global";
import { FiguresDetailTwo } from "../components/molecules/FiguresDetailTwo";

export function TechnologyData() {
  const { i18n } = useContext(Global);

  const data = {
    color: "primary",
    dataPrincipal: {
      word: i18n.t("TECH_FIRST_TAG"),
      title: i18n.t("TECH_FIRST_TITLE"),
      description: i18n.t("TECH_FIRST_DESCRIPTION"),
      reversed: true,
      figuresDetail: FiguresDetailTwo,
    },
    dataSecond: {
      word: i18n.t("TECH_SECOND_TAG"),
      title: i18n.t("TECH_SECOND_TITLE"),
      description: i18n.t("TECH_SECOND_DESCRIPTION"),

      reversed: false,
    },

    
    dropDownWeGuide: {
      titleWord: i18n.t("TECH_DROP_GUIDE_WORD"),
      title: i18n.t("TECH_DROP_GUIDE_TITLE"),
      dropDownInfo: [
        {
          title: i18n.t("TECH_DROP_GUIDE_ONE_TITLE"),
          description: i18n.t("TECH_DROP_GUIDE_ONE_DESC"),
        },
        {
          title: i18n.t("TECH_DROP_GUIDE_TWO_TITLE"),
          description: i18n.t("TECH_DROP_GUIDE_TWO_DESC"),
        },
        {
          title: i18n.t("TECH_DROP_GUIDE_THEE_TITLE"),
          description: i18n.t("TECH_DROP_GUIDE_THEE_DESC"),
        },
      ],
    },
    dropDownWeBuild: {
      titleWord: i18n.t("TECH_DROP_BUILD_WORD"),
      title: i18n.t("TECH_DROP_BUILD_TITLE"),
      dropDownInfo: [
        {
          title: i18n.t("TECH_DROP_BUILD_ONE_TITLE"),
          description: i18n.t("TECH_DROP_BUILD_ONE_DESC"),
        },
        {
          title: i18n.t("TECH_DROP_BUILD_TWO_TITLE"),
          description: i18n.t("TECH_DROP_BUILD_TWO_DESC"),
        },
        {
          title: i18n.t("TECH_DROP_BUILD_THREE_TITLE"),
          description: i18n.t("TECH_DROP_BUILD_THREE_DESC"),
        },
      ],
    },
  };
  return data;
}
