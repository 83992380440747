import React from 'react'
import { Styledh1 } from './styles'


export function HeadingXL ({color = 'blackText', children,className,...props}) {
  return (
    <Styledh1 className={` text-${color} ${className} m-0`} {...props} >
        {children}
    </Styledh1>
  )
}
