import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";

export const Div = styled.div`
width: 100%;
@media (min-width: ${breakpoints.tablet}) {
       
    width:20%;
}
    
`;

