import React from "react";
import { Footer } from "../molecules/Footer";
import { Form } from "../molecules/Form";
import { TextContact } from "../molecules/TextContact";
import { ContactTemplate } from "../templates/ContactTemplate";

import { SeparatorBrands } from "../molecules/SeparatorBrands.js";
import {Helmet} from "react-helmet"


export function Contact(props) {

  return (
    <>  
    <Helmet>
    <meta
      name="description"
      content="Consultora de negocio digital y tecnología"
    />
    <title>Contact | Consultora de negocio digital y tecnología</title>
    </Helmet>
      <ContactTemplate text={<TextContact></TextContact>} form={<Form></Form>} separator={<SeparatorBrands></SeparatorBrands>}></ContactTemplate>
      
      <Footer color='primary'></Footer>
      
    </>
  );
}
