import React, { useContext } from "react";
import { Global } from "../contexts/Global";
import { FiguresDetailThree } from "../components/molecules/FiguresDetailThree";

export function AboutUsData() {
    const { i18n } = useContext(Global);
    
  const data = {
    color: "secondary",
    dataPrincipal: {
      title: i18n.t("ABOUT_US_TITLE"),
      description: i18n.t("ABOUT_US_DESC"),
      word: i18n.t("ABOUT_US"),
      reversed: true,
      figuresDetail: FiguresDetailThree,
    },
   /*dataSecond: {
      title: i18n.t("DIGITAL_BUSINESS_TITLE"),
      description: i18n.t("DIGITAL_BUSINESS_DESC"),
      word: i18n.t("DIGITAL_BUSINESS_PRE_TITLE"),
      reversed: false,
    },
    dropDownCreateData: {
      titleWord: i18n.t("DIGITAL_BUSINESS_CREATE"),
      title: i18n.t("DIGITAL_BUSINESS_CREATE_TITLE"),
      dropDownInfo: [
        {
          title: i18n.t("MINIMUM_VIABLE_PRODUCT"),
          description: i18n.t("MINIMUM_VIABLE_PRODUCT_DESC"),
        },
        {
          title: i18n.t("PROJECT_STRATEGY"),
          description: i18n.t("PROJECT_STRATEGY_DESC"),
        },
        {
          title: i18n.t("SUPORT_AND_GEST"),
          description: i18n.t("SUPORT_AND_GEST_DESC"),
        },
      ],
    },
    dropDownLegalAdvisoryData: {
      titleWord: i18n.t("DIGITAL_BUSINESS_LEGAL_ADVISORY"),
      title: i18n.t("DIGITAL_BUSINESS_LEGAL_ADVISORY_TITLE"),
      dropDownInfo: [
        {
          title: i18n.t("CONSTITUTION_AND_PACT"),
          description: i18n.t("CONSTITUTION_AND_PACT_DESC"),
        },
        {
          title: i18n.t("FINANCING_ROUNDS"),
          description: i18n.t("FINANCING_ROUNDS_DESC"),
        },
        {
          title: i18n.t("INVERST_OPORTINITIES"),
          description: i18n.t("INVERST_OPORTINITIES_DESC"),
        },
      ],
    },*/
  };
  return data;
}
