import styled from "styled-components";

export const Input = styled.input`
-webkit-appearance: none;

     border:1px solid #F0F0F0;
     border-radius:6px;
     &::placeholder{
         font-size:14px;
         color:#B3B3B3;
     }
     &:focus-visible {
        outline:unset;
        border:1px solid #000000;
     }


`;

export const Span = styled.span`
    font-size: 14px;
    font-weight: 500;


`;