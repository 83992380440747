import styled from "styled-components";
import  Breakpoints  from "../../../styles/breakpoints";

export const Fix = styled.div`
    display:flex;
    

    margin-bottom:40px;
    @media (min-width: ${Breakpoints.tablet}) {
        width:85vw;
        position:fixed;
        flex-direction: row-reverse;
        margin-bottom:120px;
       

    }

`;