import React from 'react';
import {Div} from './styles';

export function Image ({className, image, mobileHeigth, deskHeigth, minWidth}) {
  
    return (
      <Div mobileHeigth={mobileHeigth} imageBg={image} deskHeigth={deskHeigth} minWidth={minWidth} className={`w-100 overflow-hidden justify-content-center d-flex ${className}`} >
        
      </Div>
    )
  }
  