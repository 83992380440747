import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormLabel } from "../../atoms/FormLabel";
import { Text } from "../../atoms/Text";
import { saveOnDB } from "../../../Domine/Conexion";
import { Global } from "../../../contexts/Global";
import { Button } from "./style";
import { Modal } from "../Modal";
import { AnimatePresence } from "framer-motion";

export function Form(props) {
  let history = useHistory();
  const { i18n } = useContext(Global);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [result, setResult] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    if (name === "") {
      setNameError("Oops!");
    } else if (surname === "") {
      setSurnameError("Oops!");
    } else if (email === "") {
      setEmailError("Oops!");
    } else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    ) {
      setEmailError("Invalid Mail");
    } else {
      let lol = await saveOnDB({ name, surname, email, company });
      setResult(lol);
      if (lol) {
        setName("");
        setSurname("");
        setEmail("");
        setCompany("");
        setNameError("");
        setSurnameError("");
        setEmailError("");
      }
    }
  }
  function handleChange(event, fn) {
    fn(event.target.value);
  }
  return (
    <form onSubmit={() => handleSubmit()}>
      <div className="d-flex justify-content-lg-end justify-content-center">
        <AnimatePresence>
          {result === true && (
            <Modal
              
              close={setResult}
              tilte={i18n.t("CONTACT_SUCCESS_TITLE")}
              color="tertiary"
              description={i18n.t("CONTACT_SUCCESS_DESC")}
            ></Modal>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {result === false && (
            <Modal
              
              close={setResult}
              tilte={i18n.t("CONTACT_ERROR_TITLE")}
              color="red"
              description={i18n.t("CONTACT_ERROR_DESC")}
            ></Modal>
          )}
        </AnimatePresence>
      </div>
      <div className="d-flex flex-row w-100 ">
        <FormLabel
          label={i18n.t("NAME")}
          placeholder={i18n.t("NAME_PLACE")}
          value={name}
          onChange={(event) => handleChange(event, setName)}
          required={true}
          error={nameError}
          name={"name"}
        ></FormLabel>
        <FormLabel
          className={"ms-6"}
          label={i18n.t("SURNAME")}
          placeholder={i18n.t("SURNAME_PLACE")}
          value={surname}
          onChange={(event) => handleChange(event, setSurname)}
          required={true}
          error={surnameError}
          name={"surname"}
        ></FormLabel>
      </div>
      <FormLabel
        label={i18n.t("EMAIL")}
        placeholder={i18n.t("EMAIL_PLACE")}
        value={email}
        onChange={(event) => handleChange(event, setEmail)}
        required={true}
        error={emailError}
        name={"email"}
      ></FormLabel>
      <FormLabel
        label={i18n.t("COMPANY")}
        placeholder={i18n.t("COMPANY_PLACE")}
        value={company}
        onChange={(event) => handleChange(event, setCompany)}
        required={false}
        error={false}
        name={"company"}
      ></FormLabel>
      <Button
        type="submit"
        onClick={(ev) => handleSubmit(ev)}
        className="btn btn-primary w-100 p-0 mt-6"
      >
        <Text fweight="500" fs="16px" className="mb-0 p-3 ">
          {i18n.t("SEND_FORM")}
        </Text>
      </Button>
      <Text className="text-graytext mt-3" fs="10px">
        Dwoorks te informa sobre su Política de Privacidad respecto del
        tratamiento y protección de los datos de carácter personal de los
        usuarios y clientes que puedan ser recabados por la navegación o
        contratación de servicios a través del sitio Web{" "}
        <span className="text-primary">
          www.dwoorks.com/politicasdeprivacidad
        </span>
      </Text>
    </form>
  );
}
