import React,{useContext} from "react";
import { Information } from "../organisms/Information";
import { Footer } from "../molecules/Footer";
import { AboutUsTemplate} from "../templates/AboutUsTemplate";

import { SeparatorBrands } from "../molecules/SeparatorBrands.js";
import { Global } from "../../contexts/Global";
import { AboutUsData } from "../../services/AboutUsData";
import {Helmet} from "react-helmet"


export function AboutUs(props) {
  const {i18n} = useContext(Global);
  const {color,dataPrincipal, dataSecond, dropDownCreateData, dropDownLegalAdvisoryData} = AboutUsData();

  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="No somos una consultora tradicional. Somos tu partner estratégico."
    />
    <title>About US | Consultora de negocio digital y tecnología</title>
    </Helmet>

    <AboutUsTemplate 
            buildStartups={<Information
            title={dataPrincipal.title}
            description={dataPrincipal.description}
            word={dataPrincipal.word}
            reversed={dataPrincipal.reversed}
            color={color}
            FiguresDetail={dataPrincipal.figuresDetail}
            
          >  </Information>}
          image={<SeparatorBrands ></SeparatorBrands>}
         
          ></AboutUsTemplate>
      <Footer color={color}></Footer>
    </>
  );
}
